import { UsefulLink } from '../types';

export const usefulLinks: UsefulLink[] = [
  {
    title: 'box_terms_of_use',
    routerLink: '/account/terms/box-terms-of-use'
  },
  {
    title: 'policy_against_misuse',
    routerLink: '/account/terms/policy-against-misuse'
  },
  {
    title: 'complaint_handling_policy',
    routerLink: '/account/terms/complaint-handling-policy'
  },
  {
    title: 'terms_and_conditions_for_the_provision_of_postal_services',
    routerLink: '/account/terms/terms-and-conditions-for-the-provision-of-postal-services'
  },
  {
    title: 'charter_of_obligations_towards_consumers',
    routerLink: '/account/terms/charter-of-obligations-towards-consumers'
  },
  {
    title: 'data_privacy_policy',
    routerLink: '/privacy'
  },
  {
    title: 'transparency_report',
    routerLink: '/account/terms/transparency-report'
  },
  {
    title: 'faqs_',
    routerLink: '/faqs'
  }
];
