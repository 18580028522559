import { Component, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { Order, OrderReceiptItemsGroup, OrderReceiptOffer, OrderReceiptProduct } from '@box-types';
import {
  generateOrderReceiptOfferGroups,
  generateOrderReceiptProductGroups,
  currencyFormat,
  CurrencyFormatOptions
} from '@box/utils';
import { OrderReceiptService } from './order-receipt.service';
import { CoreService, CurrencyService } from '@box-core/services';
import { ThemingService } from '@box-core/services/theming.service';

@Component({
  selector: 'order-receipt',
  templateUrl: './order-receipt.component.html',
  styleUrls: ['./order-receipt.component.scss'],
  providers: [OrderReceiptService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReceiptComponent implements OnChanges {
  @Input() public order: Order;

  public paymentMethodText: string;
  public paymentMethodIcon: string;
  public offerGroups: OrderReceiptItemsGroup<OrderReceiptOffer>[];
  public productGroups: OrderReceiptItemsGroup<OrderReceiptProduct>[];
  public formattedTip: string;
  public formattedDonation: string;
  public donationName: string;
  public formattedEnvFee: string;
  public formattedDeliveryFee: string;
  public formattedServiceFee: string;
  public formattedDiscount: string;
  public formattedOrderPrice: string;

  constructor(
    private orderReceiptService: OrderReceiptService,
    private currencyService: CurrencyService,
    private coreService: CoreService,
    private themingService: ThemingService
  ) {}

  @HostBinding('class') public hostClass = 'order-receipt';

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    const { totalPrice, tip, donation, totalEnvFee, deliveryFee, serviceFee, totalDiscountPrice } = this.order;
    this.paymentMethodText = this.orderReceiptService.getOrderPaymentMethodText(this.order);
    this.paymentMethodIcon = this.orderReceiptService.getOrderPaymentMethodIcon(this.order);
    const currencyCode = this.currencyService.getCurrencyCode();
    const groupings = this.coreService.groupings.getValue();
    const theme = this.themingService.getTheme();
    this.offerGroups = generateOrderReceiptOfferGroups(this.order, currencyCode, groupings, theme);
    this.productGroups = generateOrderReceiptProductGroups(this.order, currencyCode);
    const currencyFormatOptions = {
      minimumFractionDigits: 2,
      symbolSpace: false,
      currencyCode
    } as CurrencyFormatOptions;
    this.formattedTip = tip ? currencyFormat(tip, currencyFormatOptions) : null;
    this.formattedDonation = donation ? currencyFormat(donation, currencyFormatOptions) : null;
    this.donationName = this.orderReceiptService.getCSRDonationName();
    this.formattedEnvFee = totalEnvFee ? currencyFormat(totalEnvFee, currencyFormatOptions) : null;
    this.formattedDeliveryFee = deliveryFee ? currencyFormat(deliveryFee, currencyFormatOptions) : null;
    this.formattedServiceFee = serviceFee ? currencyFormat(serviceFee, currencyFormatOptions) : null;
    this.formattedDiscount = totalDiscountPrice ? currencyFormat(totalDiscountPrice, currencyFormatOptions) : null;
    /* The dontaion amount is not part of the Order Total price, since it will be donated to the appropriate
    organization. Therefore we need to add it to Order Price if it exists. */
    const orderPrice = totalPrice + (donation ?? 0);
    this.formattedOrderPrice = currencyFormat(orderPrice, currencyFormatOptions);
  }
}
