import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreService, ShopService } from '@box-core/services';
import { environment } from '@box-env/environment';
import {
  DiscoverSearchItems,
  DiscoverSearchItemsOptions,
  DiscoverSearchOptions,
  DiscoverSearchResponse,
  DiscoverSearchResultItem,
  DiscoverSearchShop,
  Product,
  Shop,
  DiscoverSearchResults,
  DiscoverSearchSubCategoryType,
  APIResponse,
  Offer
} from '@box-types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { appConfig } from '@box-configs/app.config';
import orderBy from 'lodash-es/orderBy';
import {
  normalizeProduct,
  getDiscoverSearchShopProductOffers,
  generateImageSrc,
  sortSearchResultsByOperatingState,
  sortShopsBasedOnOperatingState,
  sortSearchShopsByScore,
  getGroupingsNames
} from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { filterItemByGroupings } from '@box/utils/src/core/filtering.utils';

const defaultSearchResults: DiscoverSearchResults = {
  shopResults: [],
  productResults: [],
  offerResults: [],
  productsCount: 0,
  offersCount: 0
};

@Injectable()
export class DiscoverSearchService {
  private BOX_API: string = environment.application.API_URL;

  public searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public subCategory: BehaviorSubject<DiscoverSearchSubCategoryType> = new BehaviorSubject('shops');
  public searchResults: BehaviorSubject<DiscoverSearchResults> = new BehaviorSubject(defaultSearchResults);
  public showOnlyShops: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  constructor(
    private http: HttpClient,
    private shopService: ShopService,
    private coreService: CoreService,
    private globalStateService: GlobalStateService
  ) {}

  public setSearchTerm(searchTerm: string): void {
    this.searchTerm.next(searchTerm);
  }

  public getSubCategory(): DiscoverSearchSubCategoryType {
    return this.subCategory.getValue();
  }

  public setSubCategory(subCategory: DiscoverSearchSubCategoryType): void {
    this.subCategory.next(subCategory);
  }

  public setSearchResults(searchResults: DiscoverSearchResults): void {
    this.searchResults.next(searchResults);
  }

  public setShowOnlyShops(showOnlyShops: boolean): void {
    this.showOnlyShops.next(showOnlyShops);
  }

  public clearSearchTerm(): void {
    this.searchTerm.next('');
  }

  public clearSearchResults(): void {
    this.searchResults.next(defaultSearchResults);
  }

  public fetchSearchShops(options: DiscoverSearchOptions): Observable<DiscoverSearchResponse> {
    const params: HttpParams = this.generateDiscoverSearchParams(options);
    return this.http.get(`${this.BOX_API}/items`, { params }).pipe(
      map((response: APIResponse<DiscoverSearchResponse>) => response.payload),
      catchError(() => of({ shops: [], showOnlyShops: false }))
    );
  }

  public generateSearchItemsOptions(): DiscoverSearchItemsOptions {
    const minimumSearchItems = appConfig.discover.search.MINIMUM_SEARCH_ITEMS;
    const maximumSearchItems = appConfig.discover.search.MAXIMUM_SEARCH_ITEMS;
    return { minimumSearchItems, maximumSearchItems };
  }

  public generateSearchResults(shops: Shop[], discoverSearchShops: DiscoverSearchShop[]): DiscoverSearchResults {
    if (!discoverSearchShops || !shops) return defaultSearchResults;

    const shopIds = shops.map((shop) => shop._id);
    const availableSearchResultShops = discoverSearchShops.filter((searchShop) => shopIds.includes(searchShop.shopId));

    // duplicate logic in home-search.service.ts
    const searchResultShopsToBeShown = availableSearchResultShops.filter((searchShop) => searchShop.showOnShopsResult);
    const sortedSearchResultShopsByScore = sortSearchShopsByScore(searchResultShopsToBeShown, 'shopScore');
    const shopsSortedByScore = sortedSearchResultShopsByScore.reduce<Shop[]>((shopsSortedByScore, searchResult) => {
      const shop = shops.find((shop) => shop._id === searchResult.shopId);
      if (!shop) return shopsSortedByScore;
      shopsSortedByScore.push(shop);
      return shopsSortedByScore;
    }, []);
    const shopResults = sortShopsBasedOnOperatingState(shopsSortedByScore);

    const shopsWithProducts = availableSearchResultShops.filter((searchShop) => searchShop.products?.length > 0);
    const sortedSearchResultShopsByProductScore = sortSearchShopsByScore(shopsWithProducts, 'productMaxScore');
    const discoverSearchProducts = sortedSearchResultShopsByProductScore.map((searchShop) =>
      this.generateDiscoverSearchProducts(shops, searchShop)
    );
    const productResults = sortSearchResultsByOperatingState<DiscoverSearchResultItem>(discoverSearchProducts);
    const eligibleGroupings = this.globalStateService.getEligibleGroupings();
    const eligibleGroupingsNames = getGroupingsNames(eligibleGroupings);
    const filteredProducts = productResults.filter((product) =>
      filterItemByGroupings(product.shop, eligibleGroupingsNames)
    );

    const shopsWithOffers = availableSearchResultShops.filter(
      (searchShop) => searchShop.offers?.length > 0 || getDiscoverSearchShopProductOffers(searchShop)?.length > 0
    );
    const sortedSearchResultShopsByOfferScore = sortSearchShopsByScore(shopsWithOffers, 'offerMaxScore');
    const discoverSearchOffers = sortedSearchResultShopsByOfferScore.map((searchShop) =>
      this.generateDiscoverSearchOffers(shops, searchShop)
    );
    const offerResults = sortSearchResultsByOperatingState<DiscoverSearchResultItem>(discoverSearchOffers);

    const filteredOffers = offerResults.filter((offer) => filterItemByGroupings(offer.shop, eligibleGroupingsNames));

    // NaN ?? 0 => NaN
    const productsCount = availableSearchResultShops.reduce((acc, cur) => acc + cur.products?.length || 0, 0);
    const offersCount = availableSearchResultShops.reduce(
      (acc, cur) => acc + (cur.offers?.length ?? 0 + getDiscoverSearchShopProductOffers(cur)?.length ?? 0),
      0
    );

    return { shopResults, productResults: filteredProducts, offerResults: filteredOffers, productsCount, offersCount };
  }

  public offerToDiscoverSearchResultItem(offer: Offer): DiscoverSearchResultItem {
    /* we are mapping mobileImage to thumbnail here due to some ui changes we had on the shop menu items.
    In a future feature we will replace all images with a better dynamic way, instead of static properties */
    return {
      itemId: offer._id,
      price: offer.price,
      preOfferPrice: offer.preOfferPrice,
      name: offer.name,
      description: offer.unitOfMeasurementDescription,
      thumbnail: generateImageSrc(offer.mobileImage),
      measurement: offer.stepToUomDescription,
      badgeOptions: offer.badgeOptions,
      tagOptions: offer.tagOptions,
      isOffer: true,
      score: offer.score
    };
  }

  public productToDiscoverSearchResultItem(product: Product): DiscoverSearchResultItem {
    /* we are mapping mobileImage to thumbnail here due to some ui changes we had on the shop menu items.
    In a future feature we will replace all images with a better dynamic way, instead of static properties */
    return {
      itemId: product._id,
      price: product.beginPrice,
      preOfferPrice: product.finalPrice,
      name: product.name,
      description: product.unitOfMeasurementDescription || product.info?.el,
      thumbnail: generateImageSrc(product.mobileImage),
      measurement: product.stepToUomDescription,
      badgeOptions: product.badgeOptions,
      tagOptions: product.tagOptions,
      brand: product.brand,
      tags: product.tags,
      isOffer: false,
      score: product.score
    };
  }

  private generateDiscoverSearchProducts(
    shops: Shop[],
    searchShop: DiscoverSearchShop
  ): DiscoverSearchItems<DiscoverSearchResultItem> {
    const shop = shops.find((s) => s._id === searchShop.shopId);
    const items = this.generateDiscoverSearchProductItems(searchShop);
    return { shop, items };
  }

  public generateDiscoverSearchProductItems(searchShop: DiscoverSearchShop): DiscoverSearchResultItem[] {
    if (!searchShop?.products?.length) return [];
    return orderBy(searchShop.products, [(product) => product.plateScore || 0, 'score'], ['desc', 'desc']).map(
      (product) => {
        const normalizedProduct = normalizeProduct(product);
        const decoratedProduct = this.shopService.decorateProduct(normalizedProduct);
        const suggested = Boolean(decoratedProduct.plateScore);
        const suggestedDecoratedProduct = { ...decoratedProduct, suggested };
        return this.productToDiscoverSearchResultItem(suggestedDecoratedProduct);
      }
    );
  }

  private generateDiscoverSearchOffers(
    shops: Shop[],
    searchShop: DiscoverSearchShop
  ): DiscoverSearchItems<DiscoverSearchResultItem> {
    const shop = shops.find((s) => s._id === searchShop.shopId);
    const items = this.generateDiscoverSearchOfferItems(searchShop);
    return { shop, items };
  }

  public generateDiscoverSearchOfferItems(searchShop: DiscoverSearchShop): DiscoverSearchResultItem[] {
    if (!searchShop?.offers?.length && !getDiscoverSearchShopProductOffers(searchShop)?.length) return [];
    const offerSearchResults = searchShop.offers.map((offer) => {
      const decoratedOffer = this.shopService.offerDecorator(offer, []);
      return this.offerToDiscoverSearchResultItem(decoratedOffer);
    });

    const productOffersSearchResults = getDiscoverSearchShopProductOffers(searchShop).map((product) => {
      const normalizedProduct = normalizeProduct(product);
      const decoratedProduct = this.shopService.decorateProduct(normalizedProduct);
      const suggested = Boolean(decoratedProduct.plateScore);
      const suggestedDecoratedProduct = { ...decoratedProduct, suggested };
      return this.productToDiscoverSearchResultItem(suggestedDecoratedProduct);
    });

    return [...offerSearchResults, ...productOffersSearchResults].sort((a, b) => b.score - a.score);
  }

  private generateDiscoverSearchParams(options: DiscoverSearchOptions): HttpParams {
    return new HttpParams({
      fromObject: {
        term: options.term,
        businessVertical: options.businessVertical,
        latitude: String(options.address.latitude),
        longitude: String(options.address.longitude),
        ...(!!options.limit && { limit: options.limit }),
        ...(!!options.sortOnType && { sortOnType: String(options.sortOnType) })
      }
    });
  }
}
