import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AnalyticsService, RouterHistoryService, SEOService } from '@box-core/services';

@Component({
  selector: 'four-oh-four-page',
  templateUrl: './four-oh-four.page.html',
  styleUrls: ['./four-oh-four.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FourOhFourPage implements OnInit, OnDestroy {
  constructor(
    private seoService: SEOService,
    private routerHistoryService: RouterHistoryService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.seoService.setPrerenderStatusCode('404');
    this.setAnalyticsEvent();
  }

  ngOnDestroy(): void {
    this.seoService.removePrerenderStatusCode();
  }

  private setAnalyticsEvent(): void {
    const previousUrls = this.routerHistoryService.getPreviousUrlBuffer();
    if (!previousUrls) return;
    this.analyticsService.addGACustomEvent('page_not_found', { navigation_history: previousUrls });
  }
}
