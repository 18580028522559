import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BoxFooterService {
  private readonly footerIsVisibleSource = new BehaviorSubject<boolean>(false);
  public readonly footerIsVisible$ = this.footerIsVisibleSource.asObservable();

  public setFooterIsVisible(visible: boolean): void {
    this.footerIsVisibleSource.next(visible);
  }

  public getFooterIsVisible(): boolean {
    return this.footerIsVisibleSource.getValue();
  }
}
