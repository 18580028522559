import {
  Category,
  Ingredient,
  Product,
  ProductInstance,
  ProductsByCategory,
  PromoCampaign,
  Selection,
  Shop,
  ShopTagRelation,
  Tag,
  PromoVisualOptions,
  ProductChoicesChargingPolicy,
  GetTextByKeyType,
  Grouping
} from '@box-types';
import orderBy from 'lodash-es/orderBy';
import { applyPercentageDiscount, toInteger } from '../core';
import { getBestProductPromo } from '../shops';
import {
  getHotPromoVisuals,
  getPointsPromoVisuals,
  getOfferPromoVisuals,
  getGroupingBadgeVisuals
} from '../promo-campaigns';
import { uniqBy, sortBy } from 'lodash-es';

export {
  normalizeProduct,
  getProductBadgeOptions,
  getProductPromoTagOptions,
  checkFirstAvailableChoice,
  resetSelections,
  enableAllSelections,
  disableSelections,
  setSelections,
  productHasCustomPrice,
  categorizeProducts,
  categorizeOfferProducts,
  isProductPromoEligible,
  remainingRequiredChoices,
  getFreeChoiceOfTypeProduct,
  getFreeChoiceOfTypeSelection,
  getProductBaseEnvFee,
  getProductTotalEnvFee,
  getAllExceptCheapestProduct,
  getSelectedOptions,
  findIngredientWithinProduct,
  getMissingChoices,
  getNewNecessarySelections,
  getProductBaseChoice,
  getIngredientsEnvFee,
  getNewPreselectedIngredients,
  getPreselectedIngredients,
  getProductBasePrice,
  getProductIngredientsPrice,
  getProductLimitsText,
  getSelectionFreeChoices,
  decorateProductWithTags,
  decorateProductWithSuggested,
  getMaxLimitType,
  getFreeChoicesType,
  getProductFreeChoices,
  normalizeIngredient
};

function normalizeIngredient(choice: Ingredient): Ingredient {
  // change in price, envFee is not stored as a hole number, but as a decimal
  return {
    ...choice,
    changeInPrice: toInteger((choice.changeInPrice ?? 0) * 100),
    envFee: toInteger((choice.envFee ?? 0) * 100)
  };
}

function normalizeSelections(selections: Selection[]): Selection[] {
  if (!selections?.length) return [];
  return selections.map((selection) => {
    const normalizedChoices = selection.choices?.map((choice) => normalizeIngredient(choice));
    const normalizedSelectedOptions = selection.optionsSelected?.map((choice) => normalizeIngredient(choice));
    return {
      ...selection,
      ...(selection.choices ? { choices: normalizedChoices } : { choices: [] }),
      ...(selection.optionsSelected ? { optionsSelected: normalizedSelectedOptions } : { optionsSelected: [] })
    };
  });
}

function normalizeProduct(product: Product): Product {
  if (!product) return;
  return {
    ...product,
    available: product.available ?? true,
    quantity: product.quantity ?? 1,
    selections: normalizeSelections(product.selections),
    cartQuantity: product.cartQuantity ?? 0,
    displayable: product.displayable ?? true,
    minimumRequiredChoices: product.minimumRequiredChoices ?? 0,
    chargingPolicy: product.chargingPolicy ?? 'MOST_EXPENSIVE',
    productIndex: product.productIndex ?? 0
  };
}

// finds a product's default selection (e.g. savoury crepe base)
function getProductBaseChoice(selections: Selection[]): Ingredient {
  if (!selections?.length) return undefined;
  const selection = selections.filter((s) => !s.disabledChoices && s.necessary).find((s) => s.replacePrice);
  if (!selection?.choices?.length) return undefined;
  return selection.choices.find((c) => c.checked);
}

function getProductBasePrice(product: Product): number {
  const basePrice = product?.finalPrice ?? product?.price;
  if (!product?.selections?.length) return basePrice;
  const replacePriceIngredient = getProductBaseChoice(product.selections);
  return replacePriceIngredient?.changeInPrice ?? basePrice;
}

function getProductBaseEnvFee(product: Product | ProductInstance): number {
  if (!product) return 0;
  const productEnvFee = product?.envFee ?? 0;
  const defaultChoice = getProductBaseChoice(product?.selections);
  const defaultChoiceEnvFee = defaultChoice?.envFee ?? 0;
  return defaultChoiceEnvFee || productEnvFee;
}

function getIngredientsEnvFee(product: Product | ProductInstance): number {
  // the products envFee is charged whether its ingredients are free or not
  if (!product?.selections?.length) return 0;
  const selections = product.selections.filter((s) => !s.disabledChoices && !s.replacePrice && s.choices?.length);
  if (!selections?.length) return 0;
  const checkedIngredients = selections.flatMap((selection) => selection.choices.filter((c) => c.checked));
  if (!checkedIngredients?.length) return 0;
  return checkedIngredients.reduce((acc, ingredient) => acc + (ingredient.envFee ?? 0), 0);
}

// todo test
function getMaxLimitType(product: Product | ProductInstance): string {
  if (product.maxItems) return 'product';
  if (product.selections?.some((selection) => !!selection.maxItems)) return 'selection';
}

// todo test
function getFreeChoicesType(product: Product | ProductInstance): string {
  if (product.freeChoices) return 'product';
  if (product.selections?.some((selection) => !!selection.freeChoices)) return 'selection';
}

function getSelectionFreeChoicesSortingType(chargingPolicy: ProductChoicesChargingPolicy): 'asc' | 'desc' {
  if (chargingPolicy === 'LEAST_EXPENSIVE') return 'desc';
  if (chargingPolicy === 'MOST_EXPENSIVE') return 'asc';
  return 'asc';
}

// todo test
function getFreeChoiceOfTypeProduct(
  selections: Selection[],
  maximumFreeChoices: number,
  chargingPolicy: ProductChoicesChargingPolicy
): Ingredient[] {
  const choices: Ingredient[] = selections
    .filter((selection) => !!selection.choices && selection.multipleSelection)
    .flatMap((selection) => selection.choices.filter((choice) => choice.checked && choice.changeInPrice !== 0));

  const sortingType = getSelectionFreeChoicesSortingType(chargingPolicy);
  return orderBy(choices, 'changeInPrice', sortingType).slice(0, maximumFreeChoices);
}

function getSelectionFreeChoices(selection: Selection, chargingPolicy: ProductChoicesChargingPolicy): Ingredient[] {
  if (!selection?.freeChoices || selection.freeChoices < 0) return [];
  const choices: Ingredient[] = selection.choices.filter((choice) => choice.checked && choice.changeInPrice > 0);
  const sortingType = getSelectionFreeChoicesSortingType(chargingPolicy);
  return orderBy(choices, 'changeInPrice', sortingType).slice(0, selection.freeChoices);
}

function getFreeChoiceOfTypeSelection(selections: Selection[]): Ingredient[] {
  return selections
    .filter(
      (selection) => selection.freeChoices > 0 && Boolean(selection.choices?.length) && selection.multipleSelection
    )
    .flatMap((selection) => getSelectionFreeChoices(selection, selection.chargingPolicy));
}

// todo test
function getProductFreeChoices(product: Product | ProductInstance, selections: Selection[]): Ingredient[] {
  if (!selections?.length) return [];
  const type = getFreeChoicesType(product);
  if (type === 'product') return getFreeChoiceOfTypeProduct(selections, product.freeChoices, product.chargingPolicy);
  if (type === 'selection') return getFreeChoiceOfTypeSelection(selections);
  return [];
}

function getProductIngredientsPrice(product: Product | ProductInstance): number {
  if (!product?.selections?.length) return 0;
  const freeChoicesIds = getProductFreeChoices(product, product.selections).map((options) => options._id) ?? [];
  const selections = product.selections.filter((s) => !s.disabledChoices && !s.replacePrice && s.choices?.length);
  if (!selections?.length) return 0;
  const checkedIngredients = selections.flatMap((selection) => selection.choices.filter((c) => c.checked));
  if (!checkedIngredients?.length) return 0;
  return checkedIngredients.reduce((acc, ingredient) => {
    if (freeChoicesIds.includes(ingredient._id)) {
      // if the ingredient is free you still need to charge it's envFee
      return acc + (ingredient.envFee ?? 0);
    }
    // includes envFee
    return acc + (ingredient.changeInPrice ?? 0);
  }, 0);
}

function getProductTotalEnvFee(product: Product | ProductInstance): number {
  if (!product) return 0;
  return (getProductBaseEnvFee(product) + getIngredientsEnvFee(product)) * product.quantity;
}

function getProductBadgeOptions(product: Product, groupings?: Grouping[], theme?: string): PromoVisualOptions {
  if (groupings?.length > 0 && product.groupings?.length > 0) {
    const grouping = groupings.find((group) => product.groupings.includes(group.name));
    const badgeText = grouping?.texts?.find((item) => item.key === 'groupingBadge')?.text;
    if (badgeText) return getGroupingBadgeVisuals(grouping, badgeText, theme);
  }
  const productPromos = product.promos;
  if (productPromos?.length) {
    const bestProductPromo = getBestProductPromo(productPromos);
    if (!bestProductPromo?.indicator) return;
    return { image: bestProductPromo.indicator };
  }

  const suggested = product.suggested;
  if (suggested) return getHotPromoVisuals();
}

function getProductPromoTagOptions(product: Product): PromoVisualOptions {
  const productPromos = product.promos;
  if (productPromos?.length) {
    const bestProductPromo = getBestProductPromo(productPromos);
    if (!bestProductPromo?.indicator) return;
    return {
      ...getPointsPromoVisuals(),
      image: bestProductPromo.indicator
    };
  }

  const suggested = product.suggested;
  if (suggested) return getHotPromoVisuals();

  if (product.discountSticker) {
    return {
      ...getOfferPromoVisuals(),
      text: product.discountSticker
    };
  }
}

function remainingRequiredChoices(instance: ProductInstance): number {
  const { selections, minimumRequiredChoices } = instance;
  if (!selections?.length || !minimumRequiredChoices) return 0;
  const availableChoices: Ingredient[] = selections
    .filter((selection) => !selection.disabledChoices && selection.multipleSelection)
    .flatMap((selection) => selection.choices.filter((choice) => choice.available && choice.checked));
  return Math.max(minimumRequiredChoices - availableChoices.length, 0);
}

function getSelectedOptions(orderProduct: Product): Ingredient[] {
  if (!orderProduct?.selections) return [];
  return orderProduct.selections.flatMap((s) => s.optionsSelected.flatMap((i) => i));
}

// todo refactor mutation
function checkFirstAvailableChoice(selection: Selection): void {
  const hasChoices: boolean = selection.choices && selection.choices.length > 0;
  if (!hasChoices) return;
  const checkedChoice: Ingredient = selection.choices.find((c) => c.checked);
  if (checkedChoice) checkedChoice.checked = false;
  const choice: Ingredient = selection.choices.find((c) => c.available);
  if (choice) choice.checked = true;
}

// todo refactor mutations
function resetSelections(selections: Selection[]): void {
  const hasSelections: boolean = selections && selections.length > 0;
  if (!hasSelections) return undefined;
  selections.forEach((selection) => {
    const isNecessary: boolean = selection.necessary;
    if (!isNecessary) return undefined;
    const isUnavailable: boolean = selection.choices.every((c) => !c.available);
    if (isUnavailable && selection.choices.length > 0) return (selection.choices[0].checked = true);
    const hasUnavailableChoiceChecked: boolean = selection.choices.some((c) => c.checked && !c.available);
    const hasNoChoicesChecked: boolean = selection.choices.every((c) => !c.checked);
    if (hasUnavailableChoiceChecked || hasNoChoicesChecked) return checkFirstAvailableChoice(selection);
  });
}

// todo refactor mutation
function enableAllSelections(selections: Selection[]): void {
  if (!selections?.length) return;
  selections.forEach((selection) => (selection.disabledChoices = false));
}

// todo refactor mutation
function disableSelections(selections: Selection[], selectionsToDisable: string[]): void {
  if (!selectionsToDisable?.length) return;
  if (!selections?.length) return;
  selections.forEach((selection) => {
    if (selectionsToDisable.includes(selection.title)) {
      selection.disabledChoices = true;
    }
  });
}

function setSelections(instance: ProductInstance): void {
  enableAllSelections(instance.selections);
  if (!instance?.selections?.length) return;
  instance.selections
    .filter((s) => !s.multipleSelection && Boolean(s.choices?.length))
    .forEach(
      (s) =>
        !s.disabledChoices &&
        s.choices.forEach((c) => c.checked && disableSelections(instance.selections, c.disableSelections))
    );
}

function productHasCustomPrice(product: Product): boolean {
  return Boolean(product?.integrator?.customPrice);
}

function categorizeProducts(categories: Category[], products: Product[], byParent?: boolean): ProductsByCategory {
  return categories.reduce((productsByCategory: ProductsByCategory, category: Category) => {
    const categoryProducts = byParent
      ? products.filter((product) => product.category?.parent === category._id)
      : products.filter((product) => product.category?._id === category._id);
    const availableProducts = categoryProducts.filter((product) => product.available);
    const unavailableProducts = categoryProducts.filter((product) => !product.available);
    productsByCategory[category._id] = {
      available: availableProducts,
      unavailable: unavailableProducts
    };
    return productsByCategory;
  }, {});
}

function categorizeOfferProducts(categories: Category[], products: Product[], shop: Shop): ProductsByCategory {
  if (!categories?.length || !products?.length) return;
  if (shop?.integrator?.company === 'masoutis' || !shop?.isSuperMarket) {
    return categorizeProducts(categories, products);
  } else {
    return categorizeProducts(categories, products, true);
  }
}

function isProductPromoEligible(product: Product, promoCampaigns: PromoCampaign[], promoCampaignName: string): boolean {
  if (!product?.promoCampaigns?.length) return false;
  const promoCampaignNames: string[] = promoCampaigns.map((p) => p.name);
  return product.promoCampaigns.includes(promoCampaignName) && promoCampaignNames.includes(promoCampaignName);
}

function getAllExceptCheapestProduct<T>(items: (T & { price?: number })[], percentageDiscount: number): T[] {
  if (!items.length) return [];
  const productInstanceWithMinPrice = items.reduce((prev, curr) => {
    const prevPriceWithDiscount = applyPercentageDiscount(prev.price, percentageDiscount);
    const currPriceWithDiscount = applyPercentageDiscount(curr.price, percentageDiscount);
    return prevPriceWithDiscount < currPriceWithDiscount ? prev : curr;
  });
  const minPrice = applyPercentageDiscount(productInstanceWithMinPrice.price, percentageDiscount);
  const minPriceProdIndex = items.findIndex((product) => {
    const productPrice = applyPercentageDiscount(product.price, percentageDiscount);
    return productPrice === minPrice;
  });
  return [...items.slice(0, minPriceProdIndex), ...items.slice(minPriceProdIndex + 1, items.length)];
}

function findIngredientWithinProduct(ingredient: Ingredient, product: Product): Ingredient {
  if (!ingredient || !product?.selections?.length) return;
  const productSelections = product.selections.map((selection) => selection);
  if (!productSelections?.length) return;
  const productChoices = productSelections.flatMap((selection) => selection.choices);
  if (!productChoices?.length) return;
  return productChoices.find((choice) => choice._id === ingredient._id);
}

function getPreselectedIngredients(product: Product): Ingredient[] {
  if (!product?.selections?.length) return [];
  const productSelections = product.selections.map((selection) => selection);
  if (!productSelections?.length) return [];
  const productChoices = productSelections.flatMap((selection) => selection.choices);
  if (!productChoices?.length) return [];
  return productChoices.filter((choice) => choice.checked);
}

function getMissingChoices(orderProduct: Product, newProduct: Product): Ingredient[] {
  if (!orderProduct || !newProduct) return [];
  const missingChoices: Ingredient[] = [];
  const selectedOptions: Ingredient[] = getSelectedOptions(orderProduct);
  selectedOptions.forEach((orderSelectedChoice) => {
    const selectedChoiceInNewProduct = findIngredientWithinProduct(orderSelectedChoice, newProduct);
    if (!selectedChoiceInNewProduct) {
      missingChoices.push(orderSelectedChoice);
    }
  });
  return missingChoices;
}

function getNewPreselectedIngredients(orderProduct: Product, newProduct: Product): Ingredient[] {
  if (!orderProduct || !newProduct) return [];
  const selectedOptions = getSelectedOptions(orderProduct);
  const preselectedIngredients = getPreselectedIngredients(newProduct);
  if (!preselectedIngredients?.length) return [];
  if (!selectedOptions?.length) return preselectedIngredients;
  const selectedOptionsIds = selectedOptions.map((selection) => selection._id);
  return preselectedIngredients.filter(
    (preselectedIngredient) => !selectedOptionsIds.includes(preselectedIngredient._id)
  );
}

function getNewNecessarySelections(orderProduct: Product, newProduct: Product): Selection[] {
  if (!newProduct?.selections?.length || !orderProduct?.selections?.length) return [];
  const necessarySelectionIds = orderProduct?.selections
    .filter((selection) => selection.necessary === true)
    .map((selection) => selection._id);
  return newProduct.selections
    .filter((selection) => selection.necessary === true)
    .filter((newSelection) => !necessarySelectionIds.includes(newSelection._id));
}

function getProductLimitsText(product: Product, translateFn: GetTextByKeyType): string {
  const { freeChoices, maxItems } = product;
  if (freeChoices && maxItems) {
    return translateFn(
      freeChoices === 1
        ? 'and_up_to_x_additional_ingredients_the_x_free'
        : 'and_up_to_x_additional_ingredients_the_x_free_many',
      {
        _MAX_ITEMS: String(maxItems),
        _FREE_CHOICES: String(freeChoices)
      }
    );
  }

  if (maxItems)
    return translateFn('and_up_to_x_additional_ingredients', {
      _MAX_ITEMS: String(maxItems)
    });

  if (freeChoices)
    return translateFn(freeChoices === 1 ? 'the_x_free' : 'the_x_free_many', {
      _FREE_CHOICES: String(freeChoices)
    });
}

function decorateProductWithTags(product: Product, tagRelations: ShopTagRelation[], tags: Tag[]): Product {
  if (!product) return;
  const relationsTags = (tagRelations ?? [])
    .filter((relation: ShopTagRelation) => relation.productId === (product._id || product.productId))
    .flatMap((relation: ShopTagRelation) => relation.tag);
  const productTags = (tags ?? []).filter((tag) => product.tagIds?.includes(tag._id));
  const joinedTags = [...relationsTags, ...productTags];
  const uniqueTags = uniqBy<Tag>(joinedTags, '_id');
  const sortedTags = sortBy<Tag>(uniqueTags, 'tagIndex');
  return { ...product, tags: sortedTags };
}

function decorateProductWithSuggested(product: Product, suggestedProductsIds: string[]): Product {
  if (!product) return;
  if (!suggestedProductsIds?.length) return product;
  const suggested = suggestedProductsIds.includes(product._id);
  return { ...product, suggested };
}
