import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [
    // Components
    Components.OrderComponent,
    Components.OrderReceiptComponent,
    Components.OrderDeliveryDetailsComponent,
    Components.OrderReceiptItemsGroupComponent,
    Components.OrderReceiptOfferComponent,
    Components.OrderReceiptProductComponent,
    Components.OrderSMPointsComponent,
    Components.ShopOrderComponent,
    Components.ShopOrdersComponent,
    Components.ShopOrdersDialogComponent,
    Components.OrderStatusPreviewComponent,
    Components.OrderStatusPreviewsComponent,
    Components.OrderStatusLogoComponent,
    Components.OrderTileStatusComponent,
    Components.OrderStatusDocumentsDialogComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    // Components
    Components.OrderComponent,
    Components.OrderReceiptComponent,
    Components.OrderDeliveryDetailsComponent,
    Components.OrderSMPointsComponent,
    Components.ShopOrderComponent,
    Components.ShopOrdersComponent,
    Components.ShopOrdersDialogComponent,
    Components.OrderStatusPreviewComponent,
    Components.OrderStatusPreviewsComponent,
    Components.OrderStatusLogoComponent,
    Components.OrderTileStatusComponent,
    Components.OrderStatusDocumentsDialogComponent
  ]
})
export class OrdersWidgetModule {}
