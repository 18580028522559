import { Component, Renderer2, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BankPointsRedeemSlideDialogComponent } from '@box-checkout/components';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { ConfigurationService, PrivacyConsentService, SentryService } from '@box-core/services';
import dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';
import { APIResponse, CookiesConsent, CookiesConsentData, CookiesConsentInfo } from '@box-types';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { ObservableCacheDecorator } from '@box/utils';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
  selector: 'cookies-consent-dialog',
  templateUrl: './cookies-consent-dialog.component.html',
  styleUrls: ['./cookies-consent-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesConsentDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public state = 'INIT';
  public infoTexts: CookiesConsentInfo[];
  public cookiesConsent: CookiesConsent;
  public partnersNumber: string = '0';

  private routerSubscription: Subscription;

  constructor(
    public override renderer: Renderer2,
    private privacyConsentService: PrivacyConsentService,
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private sentryService: SentryService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialogRef: MatDialogRef<BankPointsRedeemSlideDialogComponent>
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.setRoutingEventsSubscriptions();
    this.getCookiesData();
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  public onSettingsClick(): void {
    this.state = 'SETTINGS';
  }

  public onBack(): void {
    this.state = 'INIT';
  }

  public onSubmit(): void {
    this.dialogRef.close();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onAcceptAll(): void {
    this.privacyConsentService.setPrivacyConsent({
      performance: true,
      ux: true,
      ad: true,
      ts: dayjs().valueOf()
    });
    this.closeDialog();
  }

  public onAcceptNecessary(): void {
    this.privacyConsentService.setPrivacyConsent({
      performance: false,
      ux: false,
      ad: false,
      ts: dayjs().valueOf()
    });
    this.privacyConsentService.removeUXData();
    this.closeDialog();
  }

  private setRoutingEventsSubscriptions(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((router: NavigationEnd) => {
        const policyUrl = '/account/personal-data/policy';
        if (router.url.includes(policyUrl)) {
          this.changeDetectorRef.detectChanges();
          this.closeDialog();
        }
      });
  }

  private getCookiesConsentUrl(): string {
    const data = this.configurationService.getConfiguration().cookiesConsent;
    return data as string;
  }

  private getCookiesData(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.fetchCookiesData()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (data) => {
          if (!data || !data.cookiesConsent) return;
          this.infoTexts = data.cookiesConsent.info;
          this.cookiesConsent = data.cookiesConsent;
          this.partnersNumber = String(data.cookiesConsent.partnersNumber);
        }
      });
  }

  @ObservableCacheDecorator<CookiesConsentData>()
  public fetchCookiesData(): Observable<CookiesConsentData> {
    const cookiesConsentUrl = this.getCookiesConsentUrl();
    if (!cookiesConsentUrl) return;
    return this.http.get<APIResponse<CookiesConsentData>>(cookiesConsentUrl).pipe(
      map((response: APIResponse<CookiesConsentData>) => response.payload),
      catchError((error) => {
        this.sentryService.captureException(error, {
          domain: 'Cookies Consent',
          severity: 'info'
        });
        return of(null);
      })
    );
  }
}
