import { constructMediaSrc, getMediaPrefix } from './media.utils';
import { CDNImage, CDNImageDimensions, CDNImageType, Image } from '@box-types';

export {
  generateImageSrc,
  generateImageSrcSet,
  generateCDNImageSrcSet,
  generateCDNImageSrc,
  getCDNImageDimensions,
  getCDNImageDimensionsByPrefix
};

const FOOD_SHOPS: CDNImageDimensions = {
  width: 116,
  height: 80
};

const MYO: CDNImageDimensions = {
  width: 232,
  height: 160
};

const GRID_LAYOUT: CDNImageDimensions = {
  width: 160,
  height: 136
};

function generateImageSrc(image: Image, prefix?: string): string {
  if (!image) return '';
  if (image.exactUrl) return image.exactUrl;
  const imagePrefix = prefix ?? getMediaPrefix(window);
  return constructMediaSrc(image, imagePrefix);
}

function generateImageSrcSet(image: Image): string {
  if (!image) return '';
  const imageX1 = generateImageSrc(image, '@1x');
  const imageX2 = generateImageSrc(image, '@2x');
  const imageX3 = generateImageSrc(image, '@3x');
  return `${imageX1} x1, ${imageX2} x2, ${imageX3} x3`;
}

function generateCDNImageSrcSet(image: CDNImage, type: CDNImageType): string {
  if (!image) return '';
  const imageX1 = generateCDNImageSrc(image, type, '@1x');
  const imageX2 = generateCDNImageSrc(image, type, '@2x');
  const imageX3 = generateCDNImageSrc(image, type, '@3x');
  return `${imageX1} x1, ${imageX2} x2, ${imageX3} x3`;
}

function generateCDNImageSrc(image: CDNImage, type: CDNImageType, prefix?: string): string {
  if (!image) return '';
  const dimensions = getCDNImageDimensions(type);
  const imagePrefix = prefix ?? getMediaPrefix(window);
  const { width, height } = getCDNImageDimensionsByPrefix(dimensions, imagePrefix);
  return image.resizable
    ? `${image.url}?v=${image.cacheBuster}&width=${width}&height=${height}&quality=100`
    : image.url;
}

function getCDNImageDimensions(type: CDNImageType): CDNImageDimensions {
  if (!type) return;
  if (type === 'FOOD_SHOPS') return FOOD_SHOPS;
  if (type === 'MYO') return MYO;
  if (type === 'GRID_LAYOUT') return GRID_LAYOUT;
}

function getCDNImageDimensionsByPrefix(dimensions: CDNImageDimensions, prefix: string): CDNImageDimensions {
  const { width, height } = dimensions;
  if (prefix === '@1x') return dimensions;
  if (prefix === '@2x') return { width: Math.round(width * 2), height: Math.round(height * 2) };
  if (prefix === '@3x') return { width: Math.round(width * 3), height: Math.round(height * 3) };
}
