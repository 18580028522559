import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogService } from '@box-core/services';
import { Order } from '@box-types';
import { formatPhoneToITU, getAddressDescription, getUserFullName, isOrderDaas } from '@box/utils';
import { BOX_SUPPORT_CONTACT_PHONE } from '@box/utils/src/order/order-acceptance.utils';
import dayjs from 'dayjs';
import { OrderStatusDocumentsDialogComponent } from '../order-status-documents-dialog/order-status-documents-dialog.component';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Component({
  selector: 'order-delivery-details',
  templateUrl: './order-delivery-details.component.html',
  styleUrls: ['./order-delivery-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDeliveryDetailsComponent implements OnInit {
  @Input() public order: Order;

  public friendlyId: string;
  public orderCreatedAtText: string;

  public shopName: string;
  public shopTaxNumber: string;
  public shopCommercialRegister: string;
  public shopAddress: string;
  public shopPhone: string;

  public recipientName: string;
  public recipientAddress: string;
  public recipientContactPhone: string;

  public administratorContactPhone = BOX_SUPPORT_CONTACT_PHONE;

  public showDocumentsButton: boolean;
  public isDaas: boolean;

  constructor(private globalStateService: GlobalStateService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.friendlyId = this.order.friendlyId;
    this.orderCreatedAtText = dayjs(this.order.createdAt).format('DD/MM/YYYY HH:mm');

    this.shopName = this.order.shop.name;
    this.shopTaxNumber = this.order.shop.taxNumber ?? '-';
    this.shopCommercialRegister = this.order.shop?.legalInfo?.commercialRegister ?? '-';
    this.shopAddress = getAddressDescription(this.order.shop.address);
    this.shopPhone = formatPhoneToITU(this.order.shop.phone);

    const user = this.globalStateService.getUser();
    this.recipientName = getUserFullName(user);
    this.recipientAddress = getAddressDescription(this.order.address);
    this.recipientContactPhone = this.order.orderContactPhone;

    this.isDaas = isOrderDaas(this.order);
    this.showDocumentsButton = Boolean(this.order?.deliveryFeeReceipt) && isOrderDaas(this.order);
  }

  public openDocumentsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: this.order
    };

    this.dialogService.openDialog(OrderStatusDocumentsDialogComponent, dialogConfig);
  }
}
