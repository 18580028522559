import { Component, ChangeDetectionStrategy, HostBinding, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OrderStatusDetailsService } from './order-status-details.service';
import { OrderStatusDetailsState } from './order-status-details.types';
import { ProgressBarFragment, GAClickContactShopConfig } from '@box-types';
import { Subscription, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from '@box-core/services';

@Component({
  selector: 'order-status-details',
  templateUrl: './order-status-details.component.html',
  styleUrls: ['./order-status-details.component.scss'],
  providers: [OrderStatusDetailsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusDetailsComponent implements OnInit, OnDestroy {
  public shopStatus: OrderStatusDetailsState;

  public fragments: ProgressBarFragment[];
  public showDaasTimingsBeforeProgress: boolean;
  public acceptedTitle: string;
  public estimationPreTitle: string;
  public estimationTitle: string;
  public estimationPostTitle: string;

  public rejectedTexts: string[];
  public contactPhone: string;
  public contactText: string;

  private orderStateSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderStatusDetailsService: OrderStatusDetailsService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public hostClass = 'order-status-details';

  ngOnInit(): void {
    this.setOrderStateSubscription();
  }

  ngOnDestroy() {
    this.orderStateSubscription?.unsubscribe();
  }

  private setOrderStateSubscription(): void {
    this.orderStateSubscription = combineLatest([
      this.orderStatusDetailsService.everyMinute(),
      this.orderStatusDetailsService
        .orderShopStatusStateChanged()
        .pipe(tap((shopStatus) => (this.shopStatus = shopStatus))),
      this.orderStatusDetailsService
        .orderDaasStatusStateChanged()
        .pipe(
          tap((showDaasTimingsBeforeProgress) => (this.showDaasTimingsBeforeProgress = showDaasTimingsBeforeProgress))
        )
    ]).subscribe(() => {
      if (this.shopStatus !== 'REJECTED' && this.orderStatusDetailsService.shouldShowBarFragments()) {
        this.fragments = this.orderStatusDetailsService.getProgressBarFragments();
      }

      if (this.shopStatus === 'ACCEPTED_COMPLETED') {
        this.acceptedTitle = this.orderStatusDetailsService.generateOrderStatusDetailsAcceptedTitle();
        this.estimationPreTitle = this.orderStatusDetailsService.generateOrderStatusEstimationPreTitle();
        this.estimationTitle = this.orderStatusDetailsService.generateOrderStatusEstimationTitle();
        this.estimationPostTitle = this.orderStatusDetailsService.generateOrderStatusEstimationPostTitle();
      }

      if (this.shopStatus === 'REJECTED') {
        this.rejectedTexts = this.orderStatusDetailsService.generateRejectedTexts();
        this.contactPhone = this.orderStatusDetailsService.getContactPhoneOrder();
        this.contactText = this.orderStatusDetailsService.getContactText();
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  public onContactTextClick(): void {
    this.triggerAnalyticsEvent();
  }

  private triggerAnalyticsEvent(): void {
    const orderStatus = this.shopStatus === 'REJECTED' ? 'not_accepted' : 'accepted';
    const gaConfig = {
      order_status: orderStatus
    } as GAClickContactShopConfig;
    this.analyticsService.addGACustomEvent('click_contact_shop', gaConfig);
  }
}
