import { Component, OnInit, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { Order } from '@box-types';
import { isOrderScheduled, getOrderTileStatusEstimationText, getOrderTileStatusTimeText } from '@box/utils';
import { translate } from '@box-core/services';

@Component({
  selector: 'order-tile-status',
  templateUrl: './order-tile-status.component.html',
  styleUrls: ['./order-tile-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTileStatusComponent implements OnInit {
  @Input() public order: Order;

  public icon: string;
  public estimationText: string;
  public estimationTime: string;

  @HostBinding('class') public hostClass = 'order-tile-status';

  ngOnInit(): void {
    const isScheduled = isOrderScheduled(this.order);
    this.icon = isScheduled ? 'today' : 'schedule';
    this.estimationText = getOrderTileStatusEstimationText(this.order);
    this.estimationTime = getOrderTileStatusTimeText(this.order, translate);
  }
}
