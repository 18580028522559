import { ConfigurationTranslations } from '@box-types';

export const enConfigTranslations: ConfigurationTranslations = {
  strings: {
    'tutorial-bubble-text': 'Enter the contest and you might be one of the winners!',
    'enf-fee-explanation':
      'This is a charge intended to support sustainability efforts, reduce carbon footprints, or invest in environmentally friendly practices.',
    'delivery-fee-explanation':
      'This amount is calculated based on the distance the store is from your order delivery point.',
    'service-fee-explanation': 'It helps us improve the delivery service, thus ensuring the best experience.',
    'learn-offers': 'To learn about all the offers!',
    'email-phone-notifications':
      "We want to keep you updated throught your email and mobile phone on all the news from BOX, so you don't miss out:",
    'discounts-coupons-gifts': 'Discounts, coupons, and great deals!',
    'new-shops': 'New stores you want to try!',
    'default-sorting-explanation':
      "The sorting of results is based on an algorithm that takes into account: the number of orders from stores in your area in the last 15 days, their popularity at BOX's discretion, their joining time at BOX, their hours of operation, and whether they participate in promotional campaigns.",
    'ratings-sorting-explanation': 'The sorting of results is based on the best reviews',
    'search-sorting-explanation': 'The sorting of results is based on the relevance of the keyword you searched',
    'cuisine-sorting-explanation':
      "The sorting of results is based on an algorithm that takes into account: the number of orders from stores in your area in the last 15 days, their popularity at BOX's discretion, their joining time at BOX, their hours of operation, and whether they participate in promotional campaigns or are chains and carry the AD marking.",
    'minimum-order-sorting-explanation': 'The sorting of results is based on the smallest minimum order value',
    'delivery-time-sorting-explanation': 'The sorting of results is based on the shortest delivery time',
    'first-step': 'The first step is completed!',
    'make-first-order': 'Place your first order now and start collecting entries.',
    'redeem-bonus-points': 'Redeem your Bonus points now and earn a discount!',
    'verify-bonus-card': 'Confirm your Bonus card details to redeem Bonus points on your BOX orders!',
    'tip-explanation':
      'The tip will be received entirely by the delivery agent! You can choose from MINIMUM_PRICE_TEXT to MAXIMUM_PRICE_TEXT.',
    'eke-explanation':
      'Choose the amount you wish to donate from MINIMUM_PRICE_TEXT to MAXIMUM_PRICE_TEXT and it will be calculated in the final price of the order.',
    'child-smile': 'The Smile of the Child',
    'support-child-smile': 'Support the Smile of the Child',
    'box-supports-child-smile':
      "BOX supports The Smile of the Child. Choose the amount you wish to donate and it will be added to your order's final price.",
    'update-app': 'Update your app!',
    'update-app-reason':
      'Update the BOX app to continue ordering safely, easily, and with even more offers and benefits!',
    'you-always-win': 'The delivery that always\nrewards you in every\nstore & dish',
    'redeem-points-to-discounts-and-gb': 'Redeem points for GB &\ndiscounts',
    'food-or-groceries': 'Food or Groceries?\nWhatever you want from over\n10,000 stores',
    '1600-for-every-4-euro':
      "For every 4€ order, you can redeem 1600 points. For every 1600 points redeemed, you earn a 1€ discount. For Super Market and Fresh Food categories, for every 10€ order, you can redeem 1600 points.\n\nIf 90 days pass without placing an order, your points are deleted. Don't worry, if you forget, we'll send you a notification before they are deleted.\n\nPoints scheme valid until 31/10/2024.",
    'gb-with-every-order-from-cosmote': 'With every order you earn GB from COSMOTE!',
    'pick-mobile-internet-plan':
      "Upon completing your order, choose the Mobile Internet bundle you want and you'll have it immediately after your order is completed!",
    'offer-available-on-cosmote':
      'The offer is available on any of the COSMOTE mobiles you have registered on the COSMOTE id platform and is valid for one order per day.',
    'cosmote-subscribers': 'COSMOTE Subscribers',
    'only-for-cosmote-subscribers': 'Only for Cosmote subscribers!',
    'gb-with-every-order-from-wu': "With every order you earn GB from WHAT'S UP!",
    'offer-available-on-wu':
      "The offer is available on any of the What's Up mobiles you have registered on the COSMOTE id platform and is valid for one order per day.",
    'wu-users': "WHAT'S UP users",
    'only-for-wu-users': "Only for our WHAT'S UP users!",
    'wu-student-wins': "What's Up Students win us over!",
    'we-love-we-students': "We love our What's Up Students!",
    'only-for-wu-students': "Only for our WHAT'S UP Students!",
    'order-now': 'Order now',
    'win-easy': 'Earn easily',
    'plus-3200-points': ' +3200 points ',
    '3200-points-worth-2': ' 3,200 points, worth €2, ',
    'every-friend-invite-they-win': 'for every friend you invite, they will also earn coupons worth ',
    '2-euro-on-3-orders':
      ' (from 2€ on 3 orders).\n\nAs soon as your friend makes their first order using your code, you will instantly earn a coupon with your points!\n\nThe offer is valid until 31/10/2024.',
    'lets-box-together': 'Let’s BOX together!',
    'redeemed-euros-from-box': 'With BOX, I have earned _EURO_ so far from redeeming points and coupons!',
    'redeemed-euros-and-gb-from-box':
      'With BOX, I have earned _EURO_ so far from redeeming points and coupons & _GB_GB of Internet!',
    'download-app-and-use-coupon-to-win':
      'Let’s BOX together! Download BOX and use the coupon COUPON_CODE to earn a total of 6€. Get a 2€ discount on each of your first 3 orders.',
    'download-app-if-not-already-and-use-coupon':
      'If you don’t have the app, download it and use the coupon COUPON_CODE to earn a total of 6€. You get a 2€ discount on each of your first 3 orders.',
    'win-3200-points': 'Earn 3200 points for every friend you invite!',
    'they-win-6': '...and they earn 6€!',
    'see-more': 'See More',
    'e-fresh-card-long': 'e-Fresh Card',
    'e-fresh-card-short': 'e-Fresh Card',
    'masoutis-card-long': 'Masoutis Mas Card',
    'masoutis-card-short': 'Mas Card',
    'krhtikos-card-long': 'Kritikos Club Card',
    'krhtikos-card-short': 'Club Card',
    'coffee-?': 'Coffee?',
    'bagel-?': 'Bagel?',
    'toast-?': 'Toast?',
    'croissant-?': 'Croissant?',
    'juice-?': 'Juice?',
    'cheese-pie-?': 'Cheese pie?',
    'milk-?': 'Milk?',
    'bread-?': 'Bread?',
    'cereal-?': 'Cereal?',
    'fruits-?': 'Fruits?',
    'fish-?': 'Fish?',
    'souvlaki-?': 'Souvlaki?',
    'cooked-?': 'Homemade?',
    'salad-?': 'Salad?',
    'pizza-?': 'Pizza?',
    'burger-?': 'Burger?',
    'ethnic-?': 'Ethnic?',
    'tea-?': 'Tea?',
    'ice-cream-?': 'Ice cream?',
    'dessert-?': 'Dessert?',
    'chinese-?': 'Chinese?',
    'sushi-?': 'Sushi?'
  },

  images: {
    'flag-greek': {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'flag_greek',
      ext: 'png'
    },
    'flag-english': {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'flag_english',
      ext: 'png'
    },
    gdpr_consent_discounts_coupons_gifts: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'gdpr-gift',
      ext: 'png'
    },
    gdpr_consent_new_shops: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'gdpr-food',
      ext: 'png'
    },
    bank_loyalty_info_bonus_logo: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogomodal',
      ext: 'png'
    },
    small_logo_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogo',
      ext: 'png'
    },
    big_logo_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuslogomodal',
      ext: 'png'
    },
    points_image_bonus: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'bonuspontousV3',
      ext: 'png'
    },
    modal_icon_eke: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'hamogelo_logo',
      ext: 'png'
    },
    checkout_indicator_eke: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'eke_indicator',
      ext: 'png'
    },
    whats_new_page_1: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_1_v2',
      ext: 'png'
    },
    whats_new_page_1_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_1_dark',
      ext: 'png'
    },
    whats_new_page_2: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_2',
      ext: 'png'
    },
    whats_new_page_2_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_2_dark',
      ext: 'png'
    },
    whats_new_page_3: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_3',
      ext: 'png'
    },
    whats_new_page_3_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/',
      name: 'whats_new_page_3_dark',
      ext: 'png'
    },
    tutorial_gif_order_process: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/tutorials/',
      name: 'Order-ProcessV2',
      ext: 'gif'
    },
    tutorial_gif_order_process_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: 'Order-Process-Dark-EN',
      ext: 'gif'
    },
    tutorial_gif_points_redemption: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/tutorials/',
      name: 'Points-Redemption-Light-EN',
      ext: 'gif'
    },
    tutorial_gif_points_redemption_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: 'Points-Redemption-Dark-EN',
      ext: 'gif'
    },
    tutorial_gif_gb_reward_claim: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/tutorials/',
      name: 'GB-Rewards-Claim-Light-EN',
      ext: 'gif'
    },
    tutorial_gif_gb_reward_claim_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: 'GB-Rewards-Claim-Dark-EN',
      ext: 'gif'
    },
    tutorial_gif_first_card_payment: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/tutorials/',
      name: '1st-Card-Payment-Light-En',
      ext: 'gif'
    },
    tutorial_gif_first_card_payment_dark: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/stage/tutorials/',
      name: '1st-Card-Payment-Dark-EN',
      ext: 'gif'
    },
    mb_skins_cosmote_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'mobile_informative_modal',
      ext: 'jpg'
    },
    mb_skins_cosmote_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'web_informative_modal',
      ext: 'png'
    },
    mb_skins_cosmote_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'cosmote-logo',
      ext: 'png'
    },
    mb_skins_whats_up_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-logo',
      ext: 'png'
    },
    mb_skins_whats_up_student_offer_indicator: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-offer-indicator-en',
      ext: 'png'
    },
    mb_skins_whats_up_student_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_student_web_informative_modal_banner: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'informative_modal_wus',
      ext: 'jpg'
    },
    mb_skins_whats_up_student_logo: {
      path: 'https://w4ve.s3.amazonaws.com/box/rewards/general/',
      name: 'whatsup-logo',
      ext: 'png'
    },
    referral_baf_v2: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'BAF_LP-min_v2',
      ext: 'png'
    },
    single_banner_section_v2_mobile: {
      path: 'https://s3.eu-central-1.amazonaws.com/w4ve/box/configuration/images/',
      name: 'BEF_decoration',
      ext: 'png'
    },
    single_banner_section_v2_web: {
      path: 'https://s3.eu-central-1.amazonaws.com/w4ve/box/configuration/images/',
      name: 'BEF_decoration',
      ext: 'png'
    },
    baf_image_model: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'BAF_image_3200',
      ext: 'png'
    },
    app_download_points_info: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'app_download_1600',
      ext: 'jpg'
    },
    sm_loyalty_info_logo_wide_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_card_flow_icon',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_order_acceptance_logo',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_loyalty_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh-big-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh-small-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_e_fresh: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'e-fresh_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_logo_wide_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_card_flow_icon',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_order_acceptance_logo',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_loyalty_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis-big-card-placeholder-new',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis-small-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_masoutis: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'masoutis_card_logo',
      ext: 'png'
    },
    sm_loyalty_info_logo_wide_kritkos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-wide',
      ext: 'png'
    },
    sm_loyalty_info_order_acceptance_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-order-acceptance',
      ext: 'png'
    },
    sm_loyalty_info_checkout_discount_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-card-checkout',
      ext: 'png'
    },
    sm_loyalty_info_big_card_placeholder_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-big-card-placeholder-new',
      ext: 'png'
    },
    sm_loyalty_info_small_card_placeholder_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-guest-card-placeholder',
      ext: 'png'
    },
    sm_loyalty_info_order_history_logo_kritikos: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'kritikos-order-history-logo',
      ext: 'png'
    },
    web_landing_heroes_1_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning',
      ext: 'png'
    },
    web_landing_heroes_1_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/morning/',
      name: 'morning-sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm',
      ext: 'png'
    },
    web_landing_heroes_2_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/sm/',
      name: 'sm-sm',
      ext: 'png'
    },
    web_landing_heroes_3_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch',
      ext: 'png'
    },
    web_landing_heroes_3_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/lunch/',
      name: 'lunch-sm',
      ext: 'png'
    },
    web_landing_heroes_4_png_xl: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_lg: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_md: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks',
      ext: 'png'
    },
    web_landing_heroes_4_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/coffee-snacks/',
      name: 'coffee-snacks-sm',
      ext: 'png'
    },
    web_landing_earn_webp: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'landing-earn',
      ext: 'webp'
    },
    web_landing_earn_png: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/images/',
      name: 'landing-earn',
      ext: 'png'
    },
    web_landing_heroes_5_png: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/dinner/',
      name: 'dinner',
      ext: 'png'
    },
    web_landing_heroes_5_png_sm: {
      path: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/onBoarding/hero/dinner/',
      name: 'dinner-sm',
      ext: 'png'
    }
  },
  links: {
    terms_link: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/termsAndConditions_EN.html',
    policy_against_misuse_link:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/policyAgainstMisuse_EN.html',
    postal_services_provision_link:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/termsAndConditionsForTheProvisionOfPostalServices_EN.html',
    obligations_towards_charter_link:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/charterOfObligationsTowardsConsumers_EN.html',
    complaint_handling_policy_link:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/complaintHandlingPolicy_EN.html',
    privacy_link: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/privacyNotice_EN.html',
    marketing_terms_link: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/marketingTerms_EN.html',
    faqs: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/faqs/faqs_EN.json',
    cookies_consent: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/cookiesConsent_EN.json',
    cookies_consent_table:
      'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/legal/cookiesConsentTable_EN.json',
    sm_loyalty_info_loyalty_terms_e_fresh: 'https://www.e-fresh.gr/el/terms-of-use',
    sm_loyalty_info_find_card_terms_url_e_fresh: 'https://www.e-fresh.gr/el/privacy-policy',
    sm_loyalty_info_personal_data_terms_url_e_fresh: 'https://www.e-fresh.gr/el/programma-epivraveuseis/karta-melous',
    sm_loyalty_info_loyalty_terms_masoutis: 'https://www.masoutis.gr/mascard/Terms',
    sm_loyalty_info_find_card_terms_url_masoutis: 'https://www.masoutis.gr/mascard/Terms',
    sm_loyalty_info_personal_data_terms_url_masoutis: 'https://www.masoutis.gr/MasCard/MasCardBox',
    sm_loyalty_info_loyalty_terms_kritikos: 'https://www.kritikos-easy.gr/terms'
  }
};
