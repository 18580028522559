import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterHistoryService {
  private routeHistory = new ReplaySubject<string>(5);

  constructor(private router: Router) {
    this.init();
  }

  public init(): void {
    this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe((event) => {
      const pathName = window?.location?.pathname;
      if (pathName !== event.url) this.routeHistory.next(pathName);
      this.routeHistory.next(event.url);
    });
  }

  public getPreviousUrl(): string {
    const buffer = this.getPreviousUrlBuffer();
    if (buffer?.length < 2) return;
    return buffer[buffer.length - 2];
  }

  public getPreviousUrlBuffer(): string[] {
    const buffer = this.routeHistory?.['_buffer'];
    if (buffer?.length < 1) return [];
    return buffer;
  }

  public getLastUrl(): string {
    const buffer = this.getPreviousUrlBuffer();
    if (buffer?.length < 1) return;
    return buffer[buffer.length - 1];
  }
}
