import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { setRedirectPrerenderMetaElements } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class LegalRedirectResolver implements Resolve<boolean> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const redirectUrl = route.data['redirectTo'];
    this.set301RedirectRule(redirectUrl);
    if (redirectUrl) this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
    return of(true);
  }

  private set301RedirectRule(url: string): void {
    setRedirectPrerenderMetaElements(window.location.origin + url);
  }
}
