import { NavItem } from '../types';

export const profileNavItems: NavItem[] = [
  {
    title: 'connect_register',
    icon: '/assets/images/general/menu/account.svg',
    clickAction: 'login',
    visibleToGuest: true,
    visibleToAuthenticatedUser: false
  },
  {
    title: 'order_history',
    icon: '/assets/images/general/menu/history.svg',
    routerLink: '/account/history',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'user_details',
    icon: '/assets/images/general/menu/account.svg',
    routerLink: '/account/user-details',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'contact_data',
    icon: '/assets/images/general/menu/account-settings.svg',
    routerLink: '/account/contact',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'addresses_',
    icon: '/assets/images/general/menu/map.svg',
    routerLink: '/account/addresses',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'payment_cards',
    icon: '/assets/images/general/menu/credit-card.svg',
    routerLink: '/account/cards',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'supermarket_cards',
    icon: '/assets/images/general/menu/grocery.svg',
    routerLink: '/account/supermarket-cards',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'rewards_coupons',
    icon: '/assets/images/general/menu/coupons.svg',
    routerLink: '/rewards/coupons',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'contests_',
    icon: '/assets/images/general/menu/star.svg',
    routerLink: '/account/contests',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'bring_a_friend',
    icon: '/assets/images/general/menu/people.svg',
    routerLink: '/account/referral',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'settings_',
    icon: '/assets/images/general/menu/settings.svg',
    routerLink: '/account/settings',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'terms_and_policies',
    icon: '/assets/images/general/menu/terms.svg',
    routerLink: '/account/terms',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'personal_data',
    icon: '/assets/images/general/menu/personal-data.svg',
    routerLink: '/account/personal-data',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'faqs_',
    icon: '/assets/images/general/menu/chat.svg',
    routerLink: '/account/faqs',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  }
];

export const termsNavItems: NavItem[] = [
  {
    title: 'privacy_update',
    routerLink: '/account/personal-data/policy',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'terms_of_personalization',
    routerLink: '/account/personal-data/personalisation',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'delete_profile',
    routerLink: '/account/personal-data/delete-profile',
    visibleToGuest: false,
    visibleToAuthenticatedUser: true
  }
];

export const settingsNavItems: NavItem[] = [
  {
    title: 'language_',
    icon: '/assets/images/general/menu/language.svg',
    routerLink: '/account/settings/language',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'dark_mode',
    icon: '/assets/images/general/menu/dark-mode.svg',
    routerLink: '/account/settings/dark-mode',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  }
];

export const termsAndPoliciesNavItems: NavItem[] = [
  {
    title: 'box_terms_of_use',
    routerLink: '/account/terms/box-terms-of-use',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'terms_and_conditions_for_the_provision_of_postal_services',
    routerLink: '/account/terms/terms-and-conditions-for-the-provision-of-postal-services',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'charter_of_obligations_towards_consumers',
    routerLink: '/account/terms/charter-of-obligations-towards-consumers',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'policy_against_misuse',
    routerLink: '/account/terms/policy-against-misuse',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'complaint_handling_policy',
    routerLink: '/account/terms/complaint-handling-policy',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  },
  {
    title: 'transparency_report',
    routerLink: '/account/terms/transparency-report',
    visibleToGuest: true,
    visibleToAuthenticatedUser: true
  }
];
