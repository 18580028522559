import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Params, Resolve } from '@angular/router';
import {
  AddressesService,
  AuthenticationService,
  GeolocationService,
  LoaderService,
  RouterHistoryService
} from '@box-core/services';
import { getClosestAddress, isAddressReadyForDelivery, isAddressReadyForView } from '@box/utils';
import { Observable, of, switchMap } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { AddressCreateDialogResponse } from '@box-shared/components/address-create-dialog/address-create-dialog.component.types';
import { Address, MapPoint } from '@box-types';
import { AddressEditDialogResponse } from '@box-shared/components/address-edit-dialog/address-edit-dialog.component.types';
import { CheckoutPage } from '@box-checkout/pages';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'root' })
export class AddressResolver implements Resolve<Address> {
  private queryParams: Params;
  private address: Address;
  private activatedRouteSnapshot: ActivatedRouteSnapshot;

  constructor(
    private addressesService: AddressesService,
    private router: Router,
    private loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private geolocationService: GeolocationService,
    private globalStateService: GlobalStateService,
    private routerHistoryService: RouterHistoryService
  ) {}

  resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<Address> {
    /*
    We transfer the params for a case that we have encountered
    with the /promos?promo={slug} redirection of the non Authenticated User.
    That functionality might need a revisit in the future, due to the fact
    that this is a Global Guard.
    */
    this.activatedRouteSnapshot = activatedRouteSnapshot;
    this.queryParams = activatedRouteSnapshot.queryParams;
    this.address = this.globalStateService.getAddress();
    if (isAddressReadyForDelivery(this.address)) return of(this.address);

    if (!this.isGoingToCheckout() && isAddressReadyForView(this.address)) return of(this.address);

    const authenticated = this.authenticationService.isAuthenticated;
    if (!authenticated) return this.initiateAddAddressDialogFlow$();

    if (this.isGoingToCheckout() && isAddressReadyForView(this.address)) {
      return this.initiateAddressCompletionFlow$(this.address);
    }

    return this.initializeAddressSelectionFlow$();
  }

  private initializeAddressSelectionFlow$(): Observable<Address> {
    this.loaderService.setState(true);
    return this.addressesService
      .getAddresses$() // the addresses information can also be fetched from the addresses.resolver
      .pipe(
        tap((addresses) => this.globalStateService.setAddresses(addresses)),
        finalize(() => this.loaderService.setState(false)),
        switchMap((addresses) => {
          if (!addresses?.length) {
            return this.initiateAddAddressDialogFlow$();
          } else if (addresses?.length === 1) {
            this.addressesService.setAndStoreAddress(addresses[0]);
            return of(addresses[0]);
          } else {
            return this.getClosestAddress$(addresses).pipe(
              switchMap((closestAddress) => {
                if (closestAddress) {
                  this.addressesService.setAndStoreAddress(closestAddress);
                  return of(closestAddress);
                }
                this.addressesService.setAndStoreAddress(addresses[0]);
                return of(addresses[0]);
              })
            );
          }
        })
      );
  }

  private initiateAddressCompletionFlow$(address: Address): Observable<Address> {
    return this.addressesService.initiateEditAddressDialogFlow$(true, address).pipe(
      switchMap((response) => {
        if (response?.address) {
          return of(response.address);
        } else {
          return this.handleRedirection$();
        }
      })
    );
  }

  private initiateAddAddressDialogFlow$(): Observable<Address> {
    const verbose = this.isGoingToCheckout();
    const addAddressDialogFlow$ = verbose
      ? this.addressesService.initiateAddFullAddressDialogFlow$()
      : this.addressesService.initiateAddSmallAddressDialogFlow$();
    return addAddressDialogFlow$.pipe(
      switchMap((response: AddressCreateDialogResponse | AddressEditDialogResponse) => {
        if (response?.address) {
          return of(response.address);
        } else {
          return this.handleRedirection$();
        }
      })
    );
  }

  private handleRedirection$(): Observable<null> {
    const previousUrl = this.routerHistoryService.getLastUrl();
    if (!previousUrl) {
      void this.router.navigate(['/'], { queryParams: this.queryParams });
      return of(null);
    }
    void this.router.navigateByUrl(previousUrl);
    return of(null);
  }

  private isGoingToCheckout(): boolean {
    return this.activatedRouteSnapshot.component === CheckoutPage;
  }

  private getClosestAddress$(addresses: Address[]): Observable<Address> {
    this.loaderService.setState(true);
    return this.geolocationService.getUserPosition$().pipe(
      finalize(() => this.loaderService.setState(false)),
      catchError(() => of(null as Address)),
      switchMap((position: GeolocationPosition) => {
        if (!position) return of(null);

        const lat = position.coords?.latitude;
        const lng = position.coords?.longitude;
        const userPoint: MapPoint = { lat: lat, lng: lng };
        const closestAddress = getClosestAddress(addresses, userPoint);
        return of(closestAddress);
      })
    );
  }
}
