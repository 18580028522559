import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@box-env/environment';
import { User, GuestUser, SegmentInfoResponse, MarketCardDetails, APIResponse, APIError } from '@box-types';
import { isStorageSupported, normalizeUser, storageGet, storageRemove, storageSet } from '@box/utils';
import { SentryService } from '@box-core/services/sentry.service';
import { LoyaltyService } from '@box-core/services/loyalty.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly DEFAULT_GUEST_USER: GuestUser = { segments: ['new_user', 'guest'] };
  private readonly BOX_API = environment.application.API_URL;
  private readonly LOCAL_STORAGE_GUEST_KEY = 'Box:guest';

  constructor(
    private http: HttpClient,
    private sentryService: SentryService,
    private loyaltyService: LoyaltyService,
    private globalStateService: GlobalStateService
  ) {
    this.handleGuestSessionExpiration();
  }

  get isGuest(): boolean {
    return !this.globalStateService.getUser()?._id;
  }

  public isNewUser(): boolean {
    return this.isGuest || !this.globalStateService.getUser()?.hasOrdered;
  }

  public addPoints(value: number): void {
    this.isGuest ? this.addGuestUserPoints(value) : this.addUserPoints(value);
  }

  public setPoints(value: number): void {
    this.isGuest ? this.setGuestPoints(value) : this.setUserPoints(value);
  }

  public getUserMainSegment(): string {
    return this.globalStateService.getUser()?.mainCosmoteAssetSegment;
  }

  public hasUserLoyaltyEvent(event: string): boolean {
    const user = this.globalStateService.getUser();
    if (!user.loyaltyEventsAlreadyUsed) return false;
    return user.loyaltyEventsAlreadyUsed.includes(event);
  }

  public hasVerifiedMSISDN(): boolean {
    const user = this.globalStateService.getUser();
    const msisdn = user?.verifiedMSISDN;
    return Boolean(msisdn?.length);
  }

  /* Guest User */

  private getGuestUser(): GuestUser {
    return (storageGet(this.LOCAL_STORAGE_GUEST_KEY, window.localStorage) ?? this.DEFAULT_GUEST_USER) as GuestUser;
  }

  private setGuestUser(user: GuestUser): void {
    if (isStorageSupported('localStorage')) storageSet(this.LOCAL_STORAGE_GUEST_KEY, user, window.localStorage);
  }

  private removeGuestUser(): void {
    storageRemove(this.LOCAL_STORAGE_GUEST_KEY, window.localStorage);
  }

  private handleFirstViewAfterRegister(): void {
    const firstPointsView = storageGet('Box:guest:firstPointsView', localStorage);
    if (this.isGuest || !firstPointsView) return;
    this.loyaltyService.setEvent('first_referral_view').subscribe({
      next: () => {
        this.addUserLoyaltyEvent('first_referral_view');
        storageRemove('Box:guest:firstPointsView', localStorage);
      },
      error: (error: APIError) => this.sentryService.captureException(error)
    });
  }

  private setGuestPoints(points: number): void {
    this.setUserPoints(points);
    const marketPlacePoints = { remainingPoints: points };
    const ts = Date.now();
    this.setGuestUser({ ...this.getGuestUser(), marketPlacePoints, ts });
  }

  private addGuestUserPoints(points: number): void {
    const currentPoints = this.globalStateService.getUser().marketPlacePoints.remainingPoints;
    this.setGuestPoints(currentPoints + points);
  }

  public setGuestLoyaltyEvent(event: string): void {
    if (this.hasUserLoyaltyEvent(event)) return undefined;
    this.addUserLoyaltyEvent(event);
    const guestUser = this.getGuestUser();
    const loyaltyEventsAlreadyUsed = [...(guestUser.loyaltyEventsAlreadyUsed ?? []), event];
    this.setGuestUser({ ...guestUser, loyaltyEventsAlreadyUsed, ts: Date.now() });
  }

  /* Guest User Ends */

  /* Authenticated User */

  public initUser(user: User = {}): void {
    const guestUser = (storageGet('Box:guest', window.localStorage) ?? this.DEFAULT_GUEST_USER) as User;
    this.globalStateService.setUser(normalizeUser({ ...guestUser, ...user }));
    this.handleFirstViewAfterRegister();
  }

  private setUserPoints(points: number): void {
    const user: User = this.globalStateService.getUser();
    user.marketPlacePoints.remainingPoints = points;
    this.globalStateService.setUser({
      ...user,
      marketPlacePoints: { ...user.marketPlacePoints, remainingPoints: points }
    });
  }

  private addUserPoints(points: number): void {
    const currentPoints = this.globalStateService.getUser()?.marketPlacePoints?.remainingPoints ?? 0;
    this.setUserPoints(currentPoints + points);
  }

  public addUserLoyaltyEvent(event: string): void {
    const user = this.globalStateService.getUser();
    const updatedEventsArray = [...(user.loyaltyEventsAlreadyUsed ?? []), event];
    this.globalStateService.setUser({ ...user, loyaltyEventsAlreadyUsed: updatedEventsArray });
  }

  public updateUserHasOrdered(hasOrdered: boolean): void {
    if (!this.isNewUser()) return;
    const user = this.globalStateService.getUser();
    this.globalStateService.setUser({ ...user, hasOrdered });
  }

  public saveLoyaltyCard(card: MarketCardDetails, created: boolean): void {
    if (!card?.name) return;
    const user = this.globalStateService.getUser();
    if (!user?.loyaltyCards) return;
    user.loyaltyCards.push(card);
    this.globalStateService.setUser(user);
    if (created) sessionStorage.setItem('Box:MarketCardCreated', 'true');
    if (card.name === 'kritikos') window.localStorage.removeItem('Box:kritikosGuestCard');
  }

  public saveTempLoyaltyCard(card: MarketCardDetails): void {
    if (!card?.name) return;
    const tempLoyaltyCards = this.globalStateService.getTempLoyaltyCards();
    this.globalStateService.setTempLoyaltyCards([...(tempLoyaltyCards ?? []), card]);
  }

  public getLoayltyCards(): MarketCardDetails[] {
    const savedCards = this.globalStateService.getSavedLoyaltyCards();
    const tempCards = this.globalStateService.getTempLoyaltyCards();
    return [...savedCards, ...tempCards];
  }

  public removeLoyaltyCardFromUser(loyaltyCard: MarketCardDetails): void {
    const user = this.globalStateService.getUser();
    const loyaltyCards = user.loyaltyCards ?? [];
    const updatedLoyaltyCards = loyaltyCards.filter((card) => card._id !== loyaltyCard._id);
    this.globalStateService.setUser({ ...user, loyaltyCards: updatedLoyaltyCards });
  }

  /* Authenticated User Ends */

  public fetchUser(): Observable<User> {
    return this.http
      .get(this.BOX_API + '/users/me')
      .pipe(map((response: APIResponse<{ user: User }>) => response.payload.user));
  }

  public fetchSegmentInfo(assets: string[]): Observable<SegmentInfoResponse> {
    const body = { assets };
    return this.http
      .put(this.BOX_API + '/users/segmentInfo', body)
      .pipe(map((response: APIResponse<SegmentInfoResponse>) => response.payload));
  }

  public fetchCosmoteAssets(): Observable<string[]> {
    return this.http.get<string[]>(`${this.BOX_API}/users/cosmoteid/cosmoteAssets`);
  }

  // Needs Overview for Refactor

  public setPointsBeforeConsumption(orderId: string): void {
    const remainingPoints = JSON.parse(window.localStorage.getItem('Box:remainingPoints')) as {
      pointsBeforeConsumption: number;
      orderId: string;
    };
    if (!remainingPoints?.pointsBeforeConsumption || !remainingPoints?.orderId) return;
    if (orderId === remainingPoints.orderId) this.setUserPoints(remainingPoints.pointsBeforeConsumption);
    if (remainingPoints) window.localStorage.removeItem('Box:remainingPoints');
  }

  public saveRemainingPointsToStorage(orderId: string): void {
    const remainingPoints = this.globalStateService.getUser()?.marketPlacePoints?.remainingPoints;
    window.localStorage.setItem(
      'Box:remainingPoints',
      JSON.stringify({ pointsBeforeConsumption: remainingPoints, orderId: orderId })
    );
  }

  private handleGuestSessionExpiration(): void {
    const guest = this.getGuestUser();
    if (!guest) return undefined;
    const expired: boolean = (Date.now() - guest.ts) / 1000 > 7200;
    if (!guest.ts || expired) this.removeGuestUser();
  }
}
