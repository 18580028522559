<footer
  data-mode="dark"
  visibilityObserver
  (enteredViewport)="onFooterEnteredViewport()"
  (leavingViewport)="onFooterLeavingViewport()"
  [intersectionRootMargin]="DEFAULT_INTERSECTION_ROOT_MARGIN"
  class="section"
>
  <div class="container footer-blocks-container">
    <div class="footer-blocks">
      <section class="footer-block contact">
        <h4 class="footer-block-title ods-typography-title-l">{{ 'communication_' | translate }}</h4>
        <div class="footer-block-content">
          <a [attr.href]="'tel:' + supportPhoneNumber" class="footer-block-content-link ods-typography-body-s-regular">
            <mat-icon>phone</mat-icon>
            <span class="ods-typography-title-s">{{ supportPhoneNumber }}</span>
          </a>
          <a [attr.href]="'mailto:' + supportEmail" class="footer-block-content-link ods-typography-body-s-regular">
            <mat-icon>email</mat-icon>
            <span class="ods-typography-title-s">{{ supportEmail }}</span>
          </a>
          <span class="footer-block-content-text ods-typography-body-s-regular">
            {{ 'our_team_is_here_to_help' | translate }}
          </span>
        </div>
      </section>

      <section class="footer-block useful">
        <h4 class="footer-block-title ods-typography-title-l">{{ 'useful_' | translate }}</h4>
        <div class="footer-block-useful-content">
          <button
            (click)="onCookiesConsentClick()"
            class="footer-block-useful-content-link footer-cookies-consent-button ods-typography-body-s-regular"
          >
            {{ 'cookies_settings' | translate }}
          </button>
          <a
            *ngFor="let link of usefulLinks"
            [routerLink]="link.routerLink"
            class="footer-block-useful-content-link ods-typography-body-s-regular"
            >{{ link.title | translate }}</a
          >
          <a
            [attr.href]="zohoUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="footer-block-useful-content-link ods-typography-body-s-regular"
            >{{ 'have_shops' | translate }}</a
          >
        </div>
      </section>

      <section class="footer-block follow">
        <h4 class="footer-block-title ods-typography-title-l">{{ 'follow_on_social' | translate }}</h4>
        <div class="box-social">
          <a [attr.href]="instagramUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <svg-icon
              loading="lazy"
              [svgPath]="'/assets/images/social/instagram.svg'"
              alt="instagram-icon"
              class="box-social-link-icon hover-opacity hoverable-animated"
            ></svg-icon>
          </a>
          <a [attr.href]="facebookUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <svg-icon
              loading="lazy"
              [svgPath]="'/assets/images/social/facebook.svg'"
              alt="facebook-icon"
              class="box-social-link-icon hover-opacity hoverable-animated"
            ></svg-icon>
          </a>
          <a [attr.href]="youtubeUrl" class="box-social-link" target="_blank" rel="noopener noreferrer">
            <svg-icon
              loading="lazy"
              [svgPath]="'/assets/images/social/youtube.svg'"
              alt="youtube-icon"
              class="box-social-link-icon hover-opacity hoverable-animated"
            ></svg-icon>
          </a>
        </div>
        <div class="footer-block-social-content">
          <span class="footer-block-social-content-text ods-typography-body-s-regular">{{
            'organism_title' | translate
          }}</span>
          <div>
            <span class="footer-block-social-content-text ods-typography-body-s-regular">{{
              'address_khfisias' | translate
            }}</span>
            <span class="footer-block-social-content-text ods-typography-body-s-regular"
              >{{ 'afm_' | translate }}: 094019245</span
            >
            <span class="footer-block-social-content-text ods-typography-body-s-regular"
              >{{ 'gemh_' | translate }}: 001037501000</span
            >
            <span class="footer-block-social-content-text ods-typography-body-s-regular">{{
              'gaty_number_compact' | translate
            }}</span>
          </div>
        </div>
      </section>

      <section class="footer-block play-store">
        <h4 class="footer-block-title ods-typography-title-l apps-footer-block">
          {{ 'take_box_with_you' | translate }}
        </h4>
        <div class="footer-block-content play-store">
          <box-app-links></box-app-links>
        </div>
      </section>
    </div>
  </div>
</footer>
