<div class="box-app-links-wrapper">
  <a
    [attr.href]="androidUrl"
    target="_blank"
    rel="noopener noreferrer"
    [attr.aria-label]="'download_android_app' | translate"
    (click)="onDownloadAppClick($event, 'Android')"
    class="box-app-links-link hoverable-animated"
  >
    <img src="/assets/images/download/google-play-logo-new.svg" alt="Download BOX to Android" />
  </a>
  <a
    [attr.href]="iosUrl"
    target="_blank"
    rel="noopener noreferrer"
    [attr.aria-label]="'download_ios_app' | translate"
    (click)="onDownloadAppClick($event, 'iOS')"
    class="box-app-links-link hoverable-animated"
  >
    <img src="/assets/images/download/app-store-logo-new.svg" alt="Download BOX to iOS"
  /></a>
  <a
    [attr.href]="huaweiUrl"
    target="_blank"
    rel="noopener noreferrer"
    [attr.aria-label]="'download_huawei_app' | translate"
    (click)="onDownloadAppClick($event, 'Huawei')"
    class="box-app-links-link hoverable-animated"
  >
    <img src="/assets/images/download/huawei-store-logo-new.svg" alt="Download BOX to Huawei"
  /></a>
</div>
