import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  AddressesService,
  ConfigurationService,
  CouponsService,
  DialogService,
  LoaderService,
  OrdersService,
  SEOService,
  ShopsService,
  UserService
} from '@box-core/services';
import { Address, APIError, FetchShopOptions, Order, Shop, SingleBannerConfiguration } from '@box-types';
import { shouldShowBAFOrderAcceptanceBanner, isOrderAccepted, isNotOrderCompleted, isOrderCompleted } from '@box/utils';
import { BehaviorSubject, Observable, Subscription, finalize, interval } from 'rxjs';
import { LanguageService } from '@box-core/services/language.service';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class OrderStatusService implements OnDestroy {
  private readonly ORDER_POLLING_INTERVAL_NUMBER = 10 * 1000; // 10 seconds
  private readonly orderSource = new BehaviorSubject<Order>(undefined);
  public readonly order$ = this.orderSource.asObservable();

  private pollingIntervalSubscription: Subscription;

  constructor(
    private activatedRouter: ActivatedRoute,
    private ordersService: OrdersService,
    private configService: ConfigurationService,
    private userService: UserService,
    private seoService: SEOService,
    private couponsService: CouponsService,
    private languageService: LanguageService,
    private addressesService: AddressesService,
    private shopsService: ShopsService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private router: Router,
    private globalStateService: GlobalStateService
  ) {}

  ngOnDestroy(): void {
    this.pollingIntervalSubscription?.unsubscribe();
  }

  public getOrder(): Order {
    return this.orderSource.getValue();
  }

  public setOrder(order: Order): void {
    this.orderSource.next(order);
  }

  public fetchOrder(): Observable<Order> {
    const friendlyId = this.activatedRouter.snapshot.queryParams.friendlyId as string;
    return this.ordersService.fetchOrder(friendlyId);
  }

  public startOrderPolling(): void {
    this.pollingIntervalSubscription = interval(this.ORDER_POLLING_INTERVAL_NUMBER).subscribe(() =>
      this.fetchOrder().subscribe((order) => this.orderSource.next(order))
    );
  }

  public stopOrderPolling(): void {
    this.pollingIntervalSubscription?.unsubscribe();
  }

  public generateSingleBannerConfig(order: Order): SingleBannerConfiguration {
    const config = this.configService.getConfiguration();
    if (!isOrderAccepted(order)) return;
    if (order.user.hasOrdered) return;
    if (!config.singleBannerSectionV2) return;
    if (!shouldShowBAFOrderAcceptanceBanner()) return;
    return { ...config.singleBannerSectionV2, shortViewOnly: true };
  }

  public shouldShowContactInfo(order: Order): boolean {
    // Once we Refactor the below function we would need to add the isOrderCompleted as well
    return isOrderAccepted(order);
  }

  /* we need to show GB Rewards from the moment that user makes an order
   * until the moment that order is considered completed.
   */
  public shouldShowGBRewards(order: Order): boolean {
    // Once we Refactor MBBAnnerComponent this will change
    return isNotOrderCompleted(order);
  }

  public shouldShowOrderDetails(order: Order): boolean {
    // Once we Refactor the below function we would need to add the isOrderCompleted as well
    return isOrderAccepted(order);
  }

  /* we need to show Order Completion Rating from the moment that user makes an order
   * until the moment that order is considered completed.
   */
  public shouldShowOrderCompletionRatings(order: Order): boolean {
    if (!isNotOrderCompleted(order)) return false;
    const user = this.globalStateService.getUser();
    return Boolean(user.gdprConsent);
  }

  public shouldShowReorderButton(order: Order): boolean {
    return isOrderCompleted(order);
  }

  public addUserPointsAfterAcceptance(order: Order) {
    const collectedPoints = order.marketPlacePoints?.collected;
    if (!collectedPoints) return;
    this.userService.addPoints(collectedPoints);
  }

  public handleUserPointsRefund(order: Order) {
    if (!order.marketPlacePoints?.consumed) return;
    this.userService.setPointsBeforeConsumption(order._id);
  }

  public handleCouponsUpdate(order: Order): void {
    if (!order.coupon) return;
    this.couponsService
      .fetchAvailableCoupons$()
      .subscribe((coupons) => this.globalStateService.setAvailableCoupons(coupons));
  }

  public setMetaTags(order: Order): void {
    /* For the Live Tracking, we might wanna get a more comprehensive title for the page
    generated by the Order Status (delviery estimation etc). For example, if the order is
    Accepted and the estimation is 45 minutes then the text could be something like:
    "45 minutes - {ShopName}" */
    const shopName = order.shop.name;
    const orderProgress = this.languageService.getTextByKey('order_progress');
    const title = `${orderProgress} - ${shopName}`;
    this.seoService.setTags({ title });
  }

  public navigateToShop(order: Order): void {
    const vanityUrl: string = order.shop.vanity_url;
    const address: Address = this.globalStateService.getAddress();
    const fetchOptions: FetchShopOptions = {
      lat: address?.latitude,
      lng: address?.longitude,
      postalCodes: address?.postalCode
    };
    this.loaderService.setState(true);
    this.shopsService
      .fetchShopByVanityUrl$(vanityUrl, fetchOptions)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (shop) => {
          if (!shop.available) return this.handleUnavailableShop();
          this.handleCompletedOrderSelection(order, shop);
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private handleUnavailableShop(): void {
    this.dialogService.openInfoDialog({
      messages: ['closed_shop', 'dont_worry_for_closed_shop']
    });
  }

  private handleCompletedOrderSelection(order: Order, shop: Shop): void {
    const options: NavigationExtras = { queryParams: { fromOrder: order.friendlyId } };
    void this.router.navigate(['/delivery', shop.locationKey, shop.vanity_url], options);
  }
}
