import {
  Shop,
  FilterableItem,
  FilterItemShopsExtraOptions,
  ShopFilterableItem,
  Coupon,
  Grouping,
  Product,
  Offer
} from '@box-types';

import { uniqBy } from 'lodash-es';
import {
  filterShopsByChainKeys,
  filterShopsByCollectionTypes,
  filterShopsByMainCuisines,
  filterShopsByMainOrSecondaryCuisines,
  filterShopsByOfferKinds,
  filterDaasShops,
  filterShopsByGroups,
  filterFrequentShops,
  filterShopsByChains,
  filterShopsByTagIds,
  filterShopsByGroupings
} from '../shops';
import { isWithinWeekDays, isEnabledByTimeRanges } from './date.utils';
import { filterShopsByPromoCampaigns } from '../promo-campaigns';
import { getCouponsSynergies } from '../coupons/coupon.utils';

function isItemEnabledForSegments<T>(
  item: T & { segments?: string[]; notEligibleForSegments?: string[] },
  segments: string[]
): boolean {
  const whiteList = item.segments ?? [];
  const blackList = item.notEligibleForSegments ?? [];
  const inBlackList = segments.some((segment) => blackList.includes(segment));
  if (inBlackList) return false;
  if (!whiteList.length) return true;
  const inWhiteList = segments.some((segment) => whiteList.includes(segment));
  if (inWhiteList) return true;
  return false;
}

function isItemEnabledForCouponSynergies<T>(
  item: T & { eligibleCouponSynergies?: string[]; notEligibleCouponSynergies?: string[] },
  couponsSynergies: string[]
): boolean {
  const whiteList = item.eligibleCouponSynergies ?? [];
  const blackList = item.notEligibleCouponSynergies ?? [];
  const inBlackList = couponsSynergies.some((synergy) => blackList.includes(synergy));
  if (inBlackList) return false;
  if (!whiteList.length) return true;
  const inWhiteList = couponsSynergies.some((synergy) => whiteList.includes(synergy));
  return inWhiteList;
}

function filterItemsBySegments<T>(
  items: (T & { segments?: string[]; notEligibleForSegments?: string[] })[],
  segments: string[]
): T[] {
  if (!items?.length) return [];
  if (!segments?.length) return items.filter((item) => !item.segments?.length);
  return items.filter((item) => isItemEnabledForSegments(item, segments));
}

function filterItemByGroupings<T>(item: T & { groupings?: string[] }, eligibleGroupings: string[]): boolean {
  if (!item?.groupings?.length) return true;
  if (!eligibleGroupings) return false;
  return item?.groupings?.some((item) => eligibleGroupings.includes(item));
}

function isFilterableItemEnabled<T extends FilterableItem>(
  item: T,
  segments: string[],
  couponsSynergies: string[],
  groupings?: string[]
): boolean {
  if (!item.enabled) return false;
  if (!isWithinWeekDays(item.enabledOnWeekDays)) return false;
  if (!isEnabledByTimeRanges(item.enabledOnTimeRanges)) return false;
  if (!isItemEnabledForSegments(item, segments)) return false;
  if (!isItemEnabledForCouponSynergies(item, couponsSynergies)) return false;
  if (!filterItemByGroupings(item, groupings)) return false;
  return true;
}

function filterEnabledItems<T extends FilterableItem>(
  items: T[],
  segments: string[],
  coupons: Coupon[],
  eligibleGroupings?: Grouping[]
): T[] {
  const couponsSynergies = getCouponsSynergies(coupons);
  const eligibleGroupingsNames = getGroupingsNames(eligibleGroupings);
  return items.filter((item) => isFilterableItemEnabled(item, segments, couponsSynergies, eligibleGroupingsNames));
}

/* Private function used for simplicity */
function filterItemShopsByShopRules<T extends ShopFilterableItem>(
  item: T,
  shops: Shop[],
  extraOptions?: FilterItemShopsExtraOptions
): Shop[] {
  if (!item || !shops?.length) return [];
  const cuisines = extraOptions?.cuisines ?? [];
  const orders = extraOptions?.orders ?? [];
  const chains = extraOptions?.chains ?? [];
  const {
    chains: chainKeys,
    shops: collectionTypes,
    shopCampaigns,
    cuisines: cuisinesKeys,
    primaryOrSecondaryCuisines: primaryOrSecondaryCuisinesKeys,
    offerKinds,
    displayDaasShops,
    displayNewShops,
    displayRecentOrderShops,
    displayPopularBrands,
    shopGroups,
    tagIds,
    groupings
  } = item;
  if (chainKeys?.length) return filterShopsByChainKeys(shops, chainKeys);
  if (collectionTypes?.length) return filterShopsByCollectionTypes(shops, collectionTypes);
  if (shopCampaigns?.length) return filterShopsByPromoCampaigns(shops, shopCampaigns);
  if (cuisinesKeys?.length || primaryOrSecondaryCuisinesKeys?.length) {
    const primaryCuisines = cuisines.filter((cuisine) => cuisinesKeys?.includes(cuisine.key));
    const primaryCuisinesShops = primaryCuisines?.length ? filterShopsByMainCuisines(shops, primaryCuisines) : [];
    if (!primaryOrSecondaryCuisinesKeys?.length) return primaryCuisinesShops;

    const primaryOrSecondaryCuisines = cuisines.filter((cuisine) =>
      primaryOrSecondaryCuisinesKeys.includes(cuisine.key)
    );
    const primaryOrSecondaryCuisinesShops = primaryOrSecondaryCuisines?.length
      ? filterShopsByMainOrSecondaryCuisines(shops, primaryOrSecondaryCuisines)
      : [];
    return uniqBy([...primaryCuisinesShops, ...primaryOrSecondaryCuisinesShops], '_id');
  }
  if (offerKinds?.length) return filterShopsByOfferKinds(shops, offerKinds);
  if (displayDaasShops) return filterDaasShops(shops);
  if (shopGroups?.length) return filterShopsByGroups(shops, shopGroups);
  if (displayNewShops) return shops.filter((shop) => shop.isNew);
  if (displayRecentOrderShops) return filterFrequentShops(shops, orders);
  if (displayPopularBrands) return filterShopsByChains(shops, chains);
  if (tagIds?.length) return filterShopsByTagIds(shops, tagIds);
  if (groupings?.length) return filterShopsByGroupings(shops, groupings);
  return [];
}

// We are gonna remove the cuisines from this function. The reason we need it right now
// is the remaining cuisine._id that are on the shop object. On future feature we will
// remove all the _id links to the shop interface
// An empty array being returned here could mean 2 things.
// If the item has shop filtering properties it means that the item is not eligible.
// if the item has no shop filtering properties it means that the item is eligible
function filterItemShops<T extends FilterableItem>(
  item: T,
  shops: Shop[],
  extraOptions?: FilterItemShopsExtraOptions
): Shop[] {
  if (!item || !shops?.length) return [];
  const { limitResultsToDaas, ineligibleShops } = item;
  const filteredShops = filterItemShopsByShopRules(item, shops, extraOptions);
  const daasFilteredShops = limitResultsToDaas ? filterDaasShops(filteredShops) : filteredShops;
  if (!ineligibleShops?.length) return daasFilteredShops;
  return daasFilteredShops.filter((shop) => !ineligibleShops.includes(shop.collectionType));
}

/* Allows you to detect if an item has any shop filtering props specified.
Can be used in conjunction with filterItemShops(item) to achieve different results
 */
function isShopFilterableItem<T>(item: T): boolean {
  if (!item) return false;

  const testObject: Required<ShopFilterableItem> = {
    shops: [],
    ineligibleShops: [],
    cuisines: [],
    primaryOrSecondaryCuisines: [],
    chains: [],
    shopCampaigns: [],
    tagIds: [],
    groupings: [],
    offerKinds: [],
    displayDaasShops: false,
    displayNewShops: false,
    displayRecentOrderShops: false,
    displayPopularBrands: false,
    limitResultsToDaas: false,
    shopGroups: []
  };

  return Object.keys(testObject).some((key) => {
    /* having an empty array means that you don't need to apply any filtering,
    it's equivalent to not having the property at all */
    if (item[key] && item[key].length === 0) return false;

    // if the item has the property and it is not an empty array then it must be checked
    if (item[key]) return true;

    return false;
  });
}

function getGroupingsNames(groupings: Grouping[]): string[] {
  return groupings?.length ? groupings.map((grouping) => grouping.name) : [];
}

export {
  isItemEnabledForSegments,
  isItemEnabledForCouponSynergies,
  filterItemsBySegments,
  isFilterableItemEnabled,
  filterEnabledItems,
  filterItemShops,
  isShopFilterableItem,
  filterItemShopsByShopRules,
  getGroupingsNames,
  filterItemByGroupings
};
