import { Cuisine, Shop, Chain, Coupon, GetTextByKeyType, CurrencyCode } from '@box-types';
import dayjs from 'dayjs';
import { generateImageSrc } from '../media';
import { getOutputBlockHtml } from '../block-editor';
import { isCouponDisabled, isCouponLoyalty, couponHasDefaultShopFiltering, getCouponBenefitText } from './coupon.utils';
import { filterItemShops, currencyFormat, formatPoints, CurrencyFormatOptions } from '../core';

const COUPON_CHECKOUT_IMAGE_PATH = '/assets/images/checkout';
const COUPON_DFU_IMAGE_NAME = 'checkout-coupon-dfu';
const COUPON_CHECKOUT_DEFAULT_IMAGE_NAME = 'checkout-coupon';

function getCouponPrimaryText(coupon: Coupon, currencyCode: CurrencyCode): string {
  if (!coupon) return;
  const { benefitType, amount, percentage, loyaltyPoints, info } = coupon;
  const primaryText = info?.primaryText;
  if (primaryText) return primaryText;
  switch (benefitType) {
    case 'DISCOUNT_FIXED':
      return '-' + currencyFormat(amount, { minimumFractionDigits: 0, currencyCode, symbolSpace: false });
    case 'DISCOUNT_PERCENTAGE':
      return '-' + new Intl.NumberFormat('el-GR', { style: 'percent' }).format(percentage / 100);
    case 'LOYALTY_POINTS':
      return formatPoints(loyaltyPoints);
  }
}
function getFinalCouponPrimaryText(
  coupon: Coupon,
  currencyCode: CurrencyCode,
  startingPrice: number,
  translate: GetTextByKeyType
): string {
  if (!coupon) return;
  if (
    !startingPrice ||
    (coupon.benefitType !== 'DISCOUNT_PERCENTAGE' && coupon.benefitType !== 'DISCOUNT_FIXED') ||
    isCouponDisabled(coupon)
  )
    return getCouponPrimaryText(coupon, currencyCode);
  return '-' + getCouponBenefitText(coupon, startingPrice, translate, currencyCode);
}

function getCouponPrimaryTextColor(coupon: Coupon, theme: string): string {
  if (!coupon) return;
  const { info } = coupon;
  if (theme === 'light') {
    return info?.primaryTextColorLight ?? '#F87A1D';
  } else {
    return info?.primaryTextColorDark ?? '#F87A1D';
  }
}

function getCouponSecondaryText(coupon: Coupon): string {
  const { benefitType, info } = coupon;
  const secondaryText = info?.secondaryText;
  if (secondaryText) return secondaryText;
  /* The case of benefitType === PERSONALISED_CAMPAIGN without secondaryText is not valid
  we should filter those coupons during the api fetch */
  switch (benefitType) {
    case 'DISCOUNT_FIXED':
    case 'DISCOUNT_PERCENTAGE':
      return 'discount_';
    case 'LOYALTY_POINTS':
      return 'points_uppercase';
  }
}

function getCouponSecondaryTextColor(coupon: Coupon, theme: string): string {
  const { info } = coupon;
  if (theme === 'light') {
    return info?.secondaryTextColorLight ?? '#F87A1D';
  } else {
    return info?.secondaryTextColorDark ?? '#F87A1D';
  }
}

function getCouponLogo(coupon: Coupon): string {
  const { info } = coupon;
  if (!info?.logo) return;
  return generateImageSrc(info.logo);
}

function shouldShowCouponExpirationTime(coupon: Coupon): boolean {
  const date = dayjs(coupon.expirationDate);
  const hours = date.get('hours');
  const minutes = date.get('minutes');
  const seconds = date.get('seconds');
  if (hours === 23 && minutes === 59 && seconds === 59) return false;
  return true;
}

function getCouponExpirationText(coupon: Coupon, translateFn: GetTextByKeyType): string {
  const { available, expirationDate, redemptionDate } = coupon;
  if (!available && redemptionDate) return translateFn('redeemed_');
  if (!available && expirationDate) return translateFn('expired_');
}

function getCouponDetailsDescription(coupon: Coupon): string {
  const blocks = coupon.info?.detailsDescription?.blocks;
  if (!blocks?.length) return;
  return blocks.map((block) => getOutputBlockHtml(block)).join('');
}

function getCouponShortDescription(coupon: Coupon, hideTopDisabledBar: boolean, translateFn: GetTextByKeyType): string {
  if (hideTopDisabledBar && coupon.disabledText) return coupon.disabledText;

  const eligibleShopsCount = coupon?.eligibleShops?.filter((shop) => shop.operatingState === 'OPEN')?.length;
  if (eligibleShopsCount === 1) return translateFn('in_shop', { SHOP_NAME: coupon?.eligibleShops[0]?.name });

  const blocks = coupon.info?.shortDescription?.blocks;
  if (!blocks?.length) return;
  const combinedBlocks = blocks.map((block) => getOutputBlockHtml(block)).join('');

  if (eligibleShopsCount > 1 && combinedBlocks.includes('SHOPS_COUNT')) {
    return combinedBlocks.replace('SHOPS_COUNT', String(eligibleShopsCount));
  }

  return combinedBlocks;
}
function getCouponShortDescriptionTextSize(
  shortDescription: string,
  coupon: Coupon,
  hideTopDisabledBar: boolean
): string {
  if (hideTopDisabledBar && coupon.disabledText && shortDescription.length > 58) return '12px';
  if (!shortDescription || shortDescription.length <= 112) return '14px';
  if (shortDescription.length > 112) return '12px';
  return '14px';
}

function getCouponDetailsExpirationDateText(coupon: Coupon, translateFn: GetTextByKeyType): string {
  if (!coupon.expirationDate) return;
  const showTime = shouldShowCouponExpirationTime(coupon);
  const formatText = showTime ? 'DD/MM/YY, HH:mm:ss' : 'DD/MM/YY';
  return translateFn('until_date', {
    _DATE: dayjs(coupon.expirationDate).format(formatText)
  });
}

function getCouponDetailsRedemptionDateText(coupon: Coupon): string {
  if (!coupon.redemptionDate) return;
  if (isCouponDisabled(coupon)) return dayjs(coupon.redemptionDate).format('DD/MM/YY, HH:mm:ss');
}

function getCouponDetailsRemainingValueText(coupon: Coupon, currencyCode: CurrencyCode): string {
  if (!coupon.info?.remainingValue) return;
  const formatOptions: CurrencyFormatOptions = { currencyCode, symbolSpace: false, minimumFractionDigits: 2 };
  return currencyFormat(coupon.info.remainingValue, formatOptions);
}

function getCouponEligibleShops(coupon: Coupon, shops: Shop[], cuisines: Cuisine[], chains: Chain[]): Shop[] {
  const filteringOptions = coupon?.info?.shopsFiltering;
  if (couponHasDefaultShopFiltering(coupon)) return shops;
  return filterItemShops(filteringOptions, shops, { cuisines, chains });
}

function getCouponDetailsCtaTitle(coupon: Coupon): string {
  if (isCouponLoyalty(coupon)) return 'activate_';
  return coupon.info?.ctaTitle;
}

function getDefaultCheckoutCouponToggleActiveImage(): string {
  return `${COUPON_CHECKOUT_IMAGE_PATH}/${COUPON_CHECKOUT_DEFAULT_IMAGE_NAME}-active.svg`;
}

function getDFUCheckoutCouponToggleActiveImage() {
  return `${COUPON_CHECKOUT_IMAGE_PATH}/${COUPON_DFU_IMAGE_NAME}-active.png`;
}

function getCouponCustomActiveImage(coupon: Coupon): string {
  if (!coupon) return;
  const imgUrl = generateImageSrc(coupon.info?.bannerImage?.checkoutChecked);
  if (imgUrl) return imgUrl;
  const isDFU = coupon.type === 'DFY';
  if (isDFU) return getDFUCheckoutCouponToggleActiveImage();
}

function getCouponImagePath(coupon: Coupon): string {
  if (!coupon) return;
  const customImg = getCouponCustomActiveImage(coupon);
  if (customImg) return customImg;
  if (coupon.loyaltyPoints || coupon.benefitType === 'PERSONALISED_CAMPAIGN')
    return getDefaultCheckoutCouponToggleActiveImage();
}

function getCouponPrimaryTextShadowColorLight(coupon: Coupon): string {
  if (!coupon) return;
  const { info } = coupon;
  return info?.primaryTextShadowColorLight ?? '#2A292E';
}

function getCouponPrimaryTextShadowColorDark(coupon: Coupon): string {
  if (!coupon) return;
  const { info } = coupon;
  return info?.primaryTextShadowColorDark ?? '#2A292E';
}
function getCouponBackgroundColor(coupon: Coupon, theme: string): string {
  if (!coupon) return;
  const { info } = coupon;
  if (theme === 'light') {
    return info?.backgroundColorLight ?? '#FEF2E8';
  } else {
    return info?.backgroundColorDark ?? '#FEF2E8';
  }
}

export {
  COUPON_CHECKOUT_IMAGE_PATH,
  COUPON_DFU_IMAGE_NAME,
  COUPON_CHECKOUT_DEFAULT_IMAGE_NAME,
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponSecondaryText,
  getCouponSecondaryTextColor,
  getCouponLogo,
  shouldShowCouponExpirationTime,
  getCouponExpirationText,
  getCouponDetailsDescription,
  getCouponDetailsExpirationDateText,
  getCouponDetailsRedemptionDateText,
  getCouponDetailsRemainingValueText,
  getCouponEligibleShops,
  getCouponDetailsCtaTitle,
  getCouponImagePath,
  getCouponCustomActiveImage,
  getDefaultCheckoutCouponToggleActiveImage,
  getDFUCheckoutCouponToggleActiveImage,
  getCouponPrimaryTextShadowColorLight,
  getCouponPrimaryTextShadowColorDark,
  getCouponShortDescription,
  getCouponBackgroundColor,
  getFinalCouponPrimaryText,
  getCouponShortDescriptionTextSize
};
