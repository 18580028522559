import { Injectable } from '@angular/core';
import { OrderStatusDetailsState } from './order-status-details.types';
import { Order, ProgressBarFragment } from '@box-types';
import {
  isOrderAccepted,
  isOrderPending,
  isOrderRejected,
  isReasonDeclinedDaas,
  isOrderDaas,
  getOrderAcceptedStatusText,
  getOrderTrackingStatusEstimationText,
  getOrderStatusTimeText,
  isOrderScheduled,
  generateOrderProgressBarFragments,
  shouldShowDaasTimingsBeforeProgress,
  getDeclinedOrderContactPhone,
  getDeclinedOrderContactText,
  isNotOrderCompleted
} from '@box/utils';
import { Observable, distinctUntilChanged, timer } from 'rxjs';
import { OrderStatusService } from '@box-checkout/pages/order-status/order-status.service';
import { map } from 'rxjs/operators';
import { translate } from '@box-core/services';

@Injectable()
export class OrderStatusDetailsService {
  constructor(private orderStatusService: OrderStatusService) {}

  public orderShopStatusStateChanged(): Observable<OrderStatusDetailsState> {
    return this.orderStatusService.order$.pipe(
      map((order: Order) => this.generateOrderStatusDetailsState(order)),
      distinctUntilChanged()
    );
  }

  public orderDaasStatusStateChanged(): Observable<boolean> {
    return this.orderStatusService.order$.pipe(
      map((order: Order) => getOrderAcceptedStatusText(order, 'orderStatus')), // allows us to detect state changes like 'delivery nearby'
      distinctUntilChanged(),
      map(() => shouldShowDaasTimingsBeforeProgress(this.orderStatusService.getOrder()))
    );
  }

  /** updates Daas deliveryETA minute estimation countdown and order delivery status */
  public everyMinute(): Observable<number> {
    return timer(0, 60 * 1000);
  }

  private generateOrderStatusDetailsState(order: Order): OrderStatusDetailsState {
    if (isOrderPending(order)) return 'PENDING';
    if (isOrderAccepted(order)) return 'ACCEPTED_COMPLETED';
    if (isOrderRejected(order)) return 'REJECTED';
  }

  public generateOrderStatusDetailsAcceptedTitle(): string {
    const order = this.orderStatusService.getOrder();
    return getOrderAcceptedStatusText(order, 'orderStatus');
  }

  public generateOrderStatusEstimationPreTitle(): string {
    const order = this.orderStatusService.getOrder();
    return getOrderTrackingStatusEstimationText(order);
  }

  public generateOrderStatusEstimationTitle(): string {
    const order = this.orderStatusService.getOrder();
    return getOrderStatusTimeText(order, translate);
  }

  public generateOrderStatusEstimationPostTitle(): string {
    const order = this.orderStatusService.getOrder();
    if (!isOrderDaas(order)) return;
    if (isOrderScheduled(order)) return 'watch_your_order_live_on_the_map';
  }

  public getProgressBarFragments(): ProgressBarFragment[] {
    const order = this.orderStatusService.getOrder();
    return generateOrderProgressBarFragments(order);
  }

  public shouldShowBarFragments(): boolean {
    const order = this.orderStatusService.getOrder();
    if (!order) return false;
    return isNotOrderCompleted(order);
  }

  public generateRejectedTexts(): string[] {
    const order = this.orderStatusService.getOrder();
    const specialDaasReason = isReasonDeclinedDaas(order);
    const showCardRefund = order.paymentType === 'card';
    const texts: string[] = [];
    if (specialDaasReason) {
      texts.push('your_order_couldnt_be_served_due_to_deliveryman_unavailability');
    }
    if (showCardRefund) {
      if (specialDaasReason) {
        texts.push('dont_worry_the_refund_has_already_been_processed');
      } else {
        texts.push('dont_worry_your_money_will_be_returned');
      }
    }
    return texts;
  }

  public getContactPhoneOrder(): string {
    const order = this.orderStatusService.getOrder();
    return getDeclinedOrderContactPhone(order);
  }

  public getContactText(): string {
    const order = this.orderStatusService.getOrder();
    return getDeclinedOrderContactText(order);
  }
}
