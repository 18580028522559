import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appConfig } from '@box-configs/app.config';
import { usefulLinks } from '@box-configs/useful-links.options';
import { BoxFooterService, DialogService } from '@box-core/services';
import { UsefulLink } from '@box-types';
import { CookiesConsentDialogComponent } from '../cookies-consent-dialog/cookies-consent-dialog.component';

@Component({
  selector: 'box-footer',
  templateUrl: './box-footer.component.html',
  styleUrls: ['./box-footer.component.scss']
})
export class BOXFooterComponent implements OnInit {
  public currentYear: number;
  public facebookUrl: string;
  public instagramUrl: string;
  public youtubeUrl: string;
  public zohoUrl: string;
  public usefulLinks: UsefulLink[];
  public supportPhoneNumber: string;
  public supportEmail: string;
  public readonly DEFAULT_INTERSECTION_ROOT_MARGIN = '0px 0px 48px 0px';

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private boxFooterService: BoxFooterService
  ) {}

  @HostBinding('class') public hostClass = 'box-footer';

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.facebookUrl = appConfig.social.FACEBOOK;
    this.instagramUrl = appConfig.social.INSTAGRAM;
    this.youtubeUrl = appConfig.social.YOUTUBE;
    this.zohoUrl = appConfig.ZOHO_URL;
    this.usefulLinks = usefulLinks;
    this.supportPhoneNumber = appConfig.support.PHONE_NUMBER;
    this.supportEmail = appConfig.support.EMAIL;
  }

  public onCookiesConsentClick(): void {
    const currentPath = this.router.url;
    const policyUrl = '/account/personal-data/policy';
    if (currentPath.includes(policyUrl)) return void this.router.navigate([policyUrl]);
    this.openCookiesConsentDialog();
  }

  private openCookiesConsentDialog(): void {
    this.dialogService.openDialog(CookiesConsentDialogComponent, {
      panelClass: 'box-dialog-fit-content',
      closeOnNavigation: true
    });
  }

  public onFooterEnteredViewport(): void {
    this.boxFooterService.setFooterIsVisible(true);
  }

  public onFooterLeavingViewport(): void {
    this.boxFooterService.setFooterIsVisible(false);
  }
}
