import { Order, OrderDeliveryStatus } from '@box-types';
import dayjs from 'dayjs';
import { getOrderDaasStatus, isOrderDaas, orderMatchesDaasInfoStatus } from './order-daas.utils';
import { getOrderShopStatus } from './order.utils';

export {
  isOrderPending,
  isOrderScheduled,
  isOrderAccepted,
  isOrderInProgress,
  isOrderRejected,
  getOrderDeliveryStatus,
  isNotOrderCompleted,
  isOrderCompleted,
  shouldPollOrderForLoyaltyStatus
};

function isOrderPending(order: Order): boolean {
  const orderStatus = order.shopResponse?.status;
  if (!orderStatus) return false;
  return orderStatus === 'PENDING';
}

/**
 * The Order is considered Scheduled while it has timeSlotStart and timeSlotEnd greater than 0,
 * and the current date is between the accepted date and 30' before the timeSlotStart
 * @param {Order} order the order to be checked
 * @returns {boolean}
 * */
function isOrderScheduled(order: Order): boolean {
  const { timeSlotStart, timeSlotEnd } = order;
  if (!timeSlotStart || !timeSlotEnd) return false;
  if (isOrderPending(order)) return true;
  const acceptedStatus = getOrderShopStatus(order, 'ACCEPTED');
  if (!acceptedStatus?.changedAt) return false;
  const currentDate = dayjs();
  const acceptedDate = dayjs.unix(acceptedStatus.changedAt);
  const preparationDate = dayjs.unix(order.timeSlotStart).subtract(30, 'minute');
  return currentDate.isBetween(acceptedDate, preparationDate);
}

function isOrderAccepted(order: Order): boolean {
  const status = order?.shopResponse?.status;
  if (!status) return false;
  const acceptedStatus = status === 'ACCEPTED' || status === 'COMPLETED';
  const loyaltyCompleted = order.loyaltyCollectionStatus !== 'PENDING';
  return acceptedStatus && loyaltyCompleted;
}

/* With this function, we assign a timeframe when the order is considered
 * as in progress. After this timeframe, the order is considered completed.
 */
function isOrderInProgress(order: Order): boolean {
  if (!isOrderAccepted(order)) return false;

  const currentDate = dayjs();
  const { shopResponse, timeSlotStart, timeSlotEnd } = order;
  const acceptedStatus = getOrderShopStatus(order, 'ACCEPTED');

  if (!acceptedStatus?.changedAt) return false;

  const timeFrameInMinutes = 45;

  if (isOrderDaas(order)) {
    const hasShippedStatus = orderMatchesDaasInfoStatus(order, 'SHIPPED');
    if (hasShippedStatus) {
      const shippedStatus = getOrderDaasStatus(order, 'SHIPPED');
      /** The next line covers cases where the order is SHIPPED but there is no record
       * of the shipped status in the `daasInformation.statusHistory`. Those are not
       * healthy cases and we consider them SHIPPED. */
      if (!shippedStatus?.changedAt) return false;
      const completedDate = dayjs.unix(shippedStatus.changedAt).add(timeFrameInMinutes, 'minute');
      if (!timeSlotEnd) {
        const acceptedDate = dayjs.unix(acceptedStatus.changedAt);
        return currentDate.isBetween(acceptedDate, completedDate);
      } else {
        const startDate = dayjs.unix(timeSlotStart).subtract(30, 'minute');
        return currentDate.isBetween(startDate, completedDate);
      }
    } else {
      const deliveryETA = order.daasInformation.deliveryETA;

      if (deliveryETA && !timeSlotEnd) {
        const acceptedDate = dayjs.unix(acceptedStatus.changedAt);
        const completedDate = dayjs(deliveryETA).add(timeFrameInMinutes, 'minute');
        return currentDate.isBetween(acceptedDate, completedDate);
      }

      if (deliveryETA && timeSlotEnd) {
        const startDate = dayjs.unix(timeSlotStart).subtract(30, 'minute');
        const endDate = dayjs(deliveryETA).add(timeFrameInMinutes, 'minute');
        return currentDate.isBetween(startDate, endDate);
      }

      if (!deliveryETA && !timeSlotEnd) {
        const acceptedDate = dayjs.unix(acceptedStatus.changedAt);
        const completedDate = dayjs(acceptedDate).add(shopResponse.minutesEstimate + 60, 'minute');
        return currentDate.isBetween(acceptedDate, completedDate);
      }

      if (!deliveryETA && timeSlotEnd) {
        const startDate = dayjs.unix(timeSlotStart).subtract(30, 'minute');
        const endDate = dayjs.unix(timeSlotEnd).add(timeFrameInMinutes, 'minute');
        return currentDate.isBetween(startDate, endDate);
      }
    }
  } else {
    if (!timeSlotEnd) {
      const acceptedDate = dayjs.unix(acceptedStatus.changedAt);
      const completedDate = dayjs(acceptedDate).add(shopResponse.minutesEstimate + timeFrameInMinutes, 'minute');
      return currentDate.isBetween(acceptedDate, completedDate);
    } else {
      const startDate = dayjs.unix(timeSlotStart).subtract(30, 'minute');
      const endDate = dayjs.unix(timeSlotEnd).add(timeFrameInMinutes, 'minute');
      return currentDate.isBetween(startDate, endDate);
    }
  }
}

function isOrderRejected(order: Order): boolean {
  const orderStatus = order?.shopResponse?.status;
  if (!orderStatus) return false;
  return ['DECLINED_MANUALLY', 'DECLINED_AUTOMATICALLY', 'PAYMENT_NBG_CANCELLED'].includes(orderStatus);
}

function shouldPollOrderForLoyaltyStatus(order: Order): boolean {
  if (!order) return false;
  return order.loyaltyCollectionStatus === 'PENDING' && order.shopResponse?.status === 'ACCEPTED';
}

/* Order Delivery Status means from the moment the merchant clicks accept */
function getOrderDeliveryStatus(order: Order): OrderDeliveryStatus {
  if (isOrderRejected(order)) return 'DECLINED';
  if (isOrderScheduled(order)) return 'SCHEDULED';
  if (isOrderInProgress(order)) return 'INPROGRESS';
  return 'COMPLETED';
}

function isNotOrderCompleted(order: Order): boolean {
  if (!order) return false;
  const orderDeliveryStatus = getOrderDeliveryStatus(order);
  if (isOrderPending(order)) return true;
  if (orderDeliveryStatus === 'INPROGRESS') return true;
  if (orderDeliveryStatus === 'SCHEDULED') return true;
  return false;
}

function isOrderCompleted(order: Order): boolean {
  if (!order) return false;
  const orderDeliveryStatus = getOrderDeliveryStatus(order);
  const isOrderStatusPending = isOrderPending(order);
  if (orderDeliveryStatus === 'COMPLETED' && !isOrderStatusPending) return true;
  return false;
}
