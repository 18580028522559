import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Coupon } from '@box-types';
import { ThemingService } from '@box-core/services/theming.service';
import { CouponTimerService } from '@box-core/services';
import { Subscription } from 'rxjs';
import {
  couponExpiresWithinDaysRange,
  getCouponPrimaryTextShadowColor,
  getFinalCouponPrimaryText,
  getPrimaryTextSize
} from '@box/utils';
import { translate } from '@box-core/services/language.service';
import { currencyCode } from '@box-core/services/currency.service';
import {
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponSecondaryText,
  getCouponSecondaryTextColor,
  getCouponExpirationText,
  getCouponLogo,
  getCouponShortDescription
} from '@box/utils';
import { getCouponShortDescriptionTextSize } from '@box/utils/src/coupons/coupon-content.utils';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public coupon: Coupon;
  @Input() public active: boolean;
  @Input() public hideTopDisabledBar: boolean;
  @Input() public showCode: boolean; // indicates we are in checkout
  @Input() public startingPrice: number;
  @Output() private expired = new EventEmitter<void>();

  public code: string;
  public available: boolean;
  public primaryText: string;
  public primaryTextColor: string;
  public primaryTextSize: string;
  public secondaryText: string;
  public secondaryTextColor: string;
  public primaryTextShadowColor: string;
  public shortDescription: string;
  public shortDescriptionTextSize: string;
  public topRightText: string;
  public disabledText: string;
  public logo: string;
  public showTopDisabledBar: boolean;
  public expirationDate: string;
  public closeToExpiration: boolean;
  public showCountDown: boolean;

  private couponTimerSubscription: Subscription;
  private expirationSubscription: Subscription;
  private themeSubscription: Subscription;

  constructor(
    private themingService: ThemingService,
    private couponTimerService: CouponTimerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'coupon';
  @HostBinding('class.coupon-unavailable') public couponUnAvailable: boolean;
  @HostBinding('class.coupon-disabled') public couponDisabled: boolean;
  @HostBinding('class.coupon-used') public couponUsed: boolean;
  @HostBinding('class.coupon-disabled-background') public couponDisabledBackground: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hideTopDisabledBar) {
      this.hideTopDisabledBar = changes.hideTopDisabledBar.currentValue as boolean;
    }

    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue as Coupon;
      this.code = this.coupon.code;
      this.expirationDate = this.coupon.expirationDate;
      this.primaryText = getFinalCouponPrimaryText(this.coupon, currencyCode, this.startingPrice, translate);
      this.primaryTextColor = getCouponPrimaryTextColor(this.coupon, this.themingService.getTheme());
      this.primaryTextSize = getPrimaryTextSize(this.primaryText);
      this.secondaryText = this.createSecondaryText();
      this.secondaryTextColor = getCouponSecondaryTextColor(this.coupon, this.themingService.getTheme());
      this.shortDescription = getCouponShortDescription(this.coupon, this.hideTopDisabledBar, translate);
      this.shortDescriptionTextSize = getCouponShortDescriptionTextSize(
        this.shortDescription,
        this.coupon,
        this.hideTopDisabledBar
      );
      this.topRightText = getCouponExpirationText(this.coupon, translate);
      this.disabledText = this.coupon.disabledText;
      this.couponDisabled = Boolean(this.disabledText);
      this.available = this.coupon.available;
      this.couponUnAvailable = !this.coupon.available;
      this.logo = getCouponLogo(this.coupon);
      this.primaryTextShadowColor = getCouponPrimaryTextShadowColor(this.coupon, this.themingService.getTheme());
      this.closeToExpiration = couponExpiresWithinDaysRange(this.coupon, 7);
    }

    if (changes.active) this.active = changes.active.currentValue as boolean;

    this.couponUsed = this.coupon.available === false && Boolean(this.coupon.redemptionDate);
    this.showTopDisabledBar = Boolean(this.disabledText) && !this.hideTopDisabledBar;
    this.couponDisabledBackground = this.showTopDisabledBar;
  }

  ngOnInit(): void {
    this.setCouponTimerSubscription();
    this.setExpirationSubscription();
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.couponTimerSubscription?.unsubscribe();
    this.expirationSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.subscribe((theme) => {
      this.primaryTextColor = getCouponPrimaryTextColor(this.coupon, theme);
      this.secondaryTextColor = getCouponSecondaryTextColor(this.coupon, theme);
      this.primaryTextShadowColor = getCouponPrimaryTextShadowColor(this.coupon, theme);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponTimerSubscription(): void {
    this.couponTimerSubscription = this.couponTimerService.getCouponTimerState$(this.code).subscribe((state) => {
      this.showCountDown = state === 'COUPON_ACTIVE' && this.closeToExpiration;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setExpirationSubscription(): void {
    this.expirationSubscription = this.couponTimerService.whenCouponIsExpired$(this.coupon.code).subscribe(() => {
      this.expired.emit();
      this.changeDetectorRef.detectChanges();
    });
  }

  private createSecondaryText(): string {
    if (this.showCode && this.coupon.benefitType === 'DISCOUNT_PERCENTAGE' && !this.coupon.disabledText) {
      return translate(getCouponSecondaryText(this.coupon)) + ' ' + getCouponPrimaryText(this.coupon, currencyCode);
    } else {
      return translate(getCouponSecondaryText(this.coupon));
    }
  }
}
