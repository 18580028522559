import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Order } from '@box-types';
import { isOrderDaas, shouldShowLiveTrackingMap, isNotOrderCompleted } from '@box/utils';
import { ThemingService } from '@box-core/services/theming.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'order-status-header',
  templateUrl: './order-status-header.component.html',
  styleUrls: ['./order-status-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public order: Order;
  public showMap: boolean;
  public showMapPicture: boolean;
  public mapHeaderBackgroundImageUrl: string;
  public mapHeaderBackgroundImageSrcSet: string;

  private themeSubscription: Subscription;

  @HostBinding('class') public hostClass = 'order-status-header';

  constructor(private themingService: ThemingService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setThemeSubscription();
    this.showMapPicture = isOrderDaas(this.order) && isNotOrderCompleted(this.order);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.order = changes.order.currentValue as Order;
    this.showMap = shouldShowLiveTrackingMap(this.order);
  }

  ngOnDestroy() {
    this.themeSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.subscribe((theme) => {
      const imageFile =
        theme !== 'dark' ? 'order-status-header-background-light.jpg' : 'order-status-header-background-dark.png';
      this.mapHeaderBackgroundImageSrcSet = ` \
      /assets/images/checkout/order-tracking/@1x${imageFile} 1x,\
       /assets/images/checkout/order-tracking/@2x${imageFile} 2x,\
        /assets/images/checkout/order-tracking/@3x${imageFile} 3x`;
      this.mapHeaderBackgroundImageUrl = '/assets/images/checkout/order-tracking/@1x${imageFile}';
      this.changeDetectorRef.detectChanges();
    });
  }
}
