import { getBestProductPromo } from '../shops';
import { getAllExceptCheapestProduct, getProductBaseEnvFee, normalizeProduct } from '../products';
import { getGroupingBadgeVisuals, getOfferPromoVisuals } from '../promo-campaigns';
import { Product, ProductPromo, OfferGroup, Offer, PromoVisualOptions, Grouping } from '@box-types';
import { filterItemByGroupings } from '../core/filtering.utils';

export {
  getDefaultOfferProductsWithoutCheapest,
  offerRequiresMinOrderItemsPrice,
  decorateGroupWithSuggestedProduct,
  normalizeOffer,
  isDFYOffer,
  getOfferType,
  getOfferBadgeOptions,
  getOfferTagOptions,
  getOfferBaseEnvFee,
  getAllProductsFromOffer,
  offerIncludesOverrideOrderRule,
  getOfferMaxItemsPerOrder,
  getOfferGroupPreselectedProduct,
  isOfferDisplayable
};

function getOfferGroupPreselectedProduct(group: OfferGroup): Product {
  if (!group?.products?.length) return undefined;
  return group.products.sort((prev, next) => prev.productIndex - next.productIndex)[0];
}

// private
function getDefaultOfferProductsWithoutCheapest(offer: Offer): Product[] {
  if (!offer?.groups?.length) return undefined;
  const products: Product[] = offer.groups
    .filter((group) => group.type !== 'gift' && group.products?.length)
    .flatMap((group) => getOfferGroupPreselectedProduct(group));
  return getAllExceptCheapestProduct(products, offer?.percentageDiscount);
}

// gets all offers preselected products including gift ones
function getOfferDefaultProducts(offer: Offer): Product[] {
  if (!offer?.groups?.length) return [];
  return offer.groups.flatMap((group) => getOfferGroupPreselectedProduct(group));
}

// gets only the offers preselected products the user is going to pay
function getOfferDefaultChargedProducts(offer: Offer): Product[] {
  if (!offer?.groups?.length) return [];
  const nonGiftGroups = offer.groups.filter((g) => g.type !== 'gift');
  const groupDefaultProducts = nonGiftGroups.flatMap((group) => getOfferGroupPreselectedProduct(group));
  if (offer.rule !== 'minPriceAsGift') return groupDefaultProducts;
  return getDefaultOfferProductsWithoutCheapest(offer);
}

function offerRequiresMinOrderItemsPrice(offer: Offer): boolean {
  return Boolean(offer.minOrderItemsPrice);
}

function getOfferBaseEnvFee(offer: Offer): number {
  if (!offer) return 0;
  const offerDefaultProducts = offer.envFeeChargedForOfferGifts
    ? getOfferDefaultProducts(offer)
    : getOfferDefaultChargedProducts(offer);
  if (!offerDefaultProducts?.length) return 0;
  return offerDefaultProducts.reduce((acc, prod) => acc + getProductBaseEnvFee(prod), 0) ?? 0;
}

function decorateGroupWithSuggestedProduct(group: OfferGroup, suggestedProductsIds: string[]): OfferGroup {
  const products = group?.products.map((product) => ({
    ...product,
    suggested: suggestedProductsIds.includes(product.productId)
  }));
  return { ...group, products };
}

function normalizeOfferGroup(group: OfferGroup): OfferGroup {
  return {
    ...group,
    products: group.products?.map((product) => normalizeProduct(product)),
    ...(group.selectedProduct && { selectedProduct: normalizeProduct(group.selectedProduct) }) // conditional parameter
  };
}

function normalizeOffer(offer: Offer): Offer {
  if (!offer) return;
  const productGroups = offer?.groups ?? [];
  return {
    ...offer,
    quantity: offer.quantity ?? 0,
    ...(offer.products && { products: offer.products?.map((product) => normalizeProduct(product)) }),
    ...(offer.groups && { groups: offer.groups?.map((group) => normalizeOfferGroup(group)) }),
    cartQuantity: offer.cartQuantity ?? 0,
    displayable: offer.displayable ?? true,
    available: offer.available ?? true,
    groups: productGroups.map((group) => ({
      ...group,
      products: group.products?.map((product) => normalizeProduct(product)) ?? [],
      ...(group.selectedProduct && { selectedProduct: normalizeProduct(group.selectedProduct) })
    })),
    offerIndex: offer.offerIndex ?? 0
  };
}

function isDFYOffer(offer: Offer): boolean {
  return offer.couponType && offer.couponType === 'dfu' && offer.needsCouponToRedeem;
}

function getOfferType(offer: Offer): string {
  if (offer.isDFY) return 'DFY';
  const sticker = Boolean(offer?.webSticker);
  if (sticker) return 'normal';
  return undefined;
}

function getOfferBadgeOptions(
  offer: Offer,
  productPromos: ProductPromo[],
  groupings?: Grouping[],
  theme?: string
): PromoVisualOptions {
  if (!offer) return;
  if (groupings?.length > 0 && offer.groupings?.length > 0) {
    const grouping = groupings.find((group) => offer.groupings.includes(group.name));
    const badgeText = grouping?.texts?.find((item) => item.key === 'groupingBadge')?.text;
    if (badgeText) return getGroupingBadgeVisuals(grouping, badgeText, theme);
  }
  const bestProductPromo = productPromos?.length ? getBestProductPromo(productPromos) : null;
  if (bestProductPromo?.indicator) return { image: bestProductPromo.indicator };
  const offerType = getOfferType(offer);
  if (offerType === 'DFY') return { image: '/assets/images/offers/DFY.svg' };
  if (offerType === 'normal') return { ...getOfferPromoVisuals(), text: offer.webSticker };
}

function getOfferTagOptions(offer: Offer, productPromos: ProductPromo[]): PromoVisualOptions {
  if (!offer) return;
  const bestProductPromo = productPromos?.length ? getBestProductPromo(productPromos) : null;
  if (bestProductPromo?.indicator) return { image: bestProductPromo.indicator };
  if (offer.webSticker) return { ...getOfferPromoVisuals(), text: offer.webSticker };
}

function getAllProductsFromOffer(offer: Offer): Product[] {
  if (!offer?.groups?.length) return [];
  return offer.groups.flatMap((group) => group?.products).filter(Boolean);
}

function offerIncludesOverrideOrderRule(offer: Offer, rule: string): boolean {
  if (!offer.overrideOrderRules?.length) return false;
  return offer.overrideOrderRules.includes(rule);
}

function getOfferMaxItemsPerOrder(offer: Offer): number {
  const { maxItemsPerOrder, maxQuantityPerOrder } = offer;
  return maxQuantityPerOrder ?? maxItemsPerOrder;
}

function isOfferDisplayable(offer: Offer, eligibleGroupingsNames: string[]): boolean {
  if (!offer.displayable) return false;
  if (!offer.groups?.length) return false;
  if (!filterItemByGroupings(offer, eligibleGroupingsNames)) return false;

  const invalidGroup = offer.groups.some((group) => {
    if (!group.products?.length) return true;
    if (group.products.every((product) => !product.available || !product.displayable)) return true;
    return false;
  });

  if (invalidGroup) return false;
  return true;
}
