import { Injectable } from '@angular/core';
import { translate } from '@box-core/services';
import { jsPDF } from 'jspdf';
import { font as boldFont } from '@box-assets/fonts/PFDinTextPro-Bold-bold.js';
import { font as normalFont } from '@box-assets/fonts/PFDinTextPro-Regular-normal.js';
import { Order, PDFGroup } from '@box-types';
import dayjs from 'dayjs';
import { formatPhoneToITU, getAddressDescription, getUserFullName } from '@box/utils';
import { GlobalStateService } from '@box-core/services/global-state.service';

@Injectable()
export class OrderStatusDocumentsDialogService {
  constructor(private globalStateService: GlobalStateService) {}

  // https://raw.githack.com/MrRio/jsPDF/master/index.html
  public generateOrderDeliveryDetailsPDF(order: Order): void {
    if (!order) return;
    const pdf = new jsPDF();
    this.setPdfCustomFonts(pdf);
    this.setHeaderPdfIcon(pdf);
    this.setHeaderPdfData(pdf);
    this.setBodyPdfData(pdf, order);
    pdf.save('delivery_details.pdf');
  }

  private setPdfCustomFonts(pdf: jsPDF): void {
    pdf.addFileToVFS('PFDinTextPro.bold', boldFont);
    pdf.addFileToVFS('PFDinTextPro.normal', normalFont);
    pdf.addFont('PFDinTextPro.bold', 'pfd.bold', 'bold');
    pdf.addFont('PFDinTextPro.normal', 'pfd.normal', 'normal');
    pdf.setFont('pfd.bold', 'bold');
    pdf.setFontSize(11);
    pdf.setTextColor('#000000');
  }

  private setHeaderPdfIcon(pdf: jsPDF): void {
    const pageWidth = pdf.internal.pageSize.width;
    pdf.addImage('../../../../../assets/images/logo/box-unpacked-logo.jpg', 'JPEG', pageWidth / 2 - 10, 10, 20, 16);
  }

  private setHeaderPdfData(pdf: jsPDF): void {
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width; // Get full PDF width
    const maxTextWidth = pageWidth / 2.6;
    const textHeader1 = translate('organism_title_uppercase');
    const header1 = pdf.splitTextToSize(textHeader1, maxTextWidth);
    const header2 = translate('afm_') + ': 094019245';
    const textHeader3 = translate('gaty_number_uppercase');
    const header3 = pdf.splitTextToSize(textHeader3, maxTextWidth);
    const header4 = translate('phone_uppercase') + ': +302106241990';
    // set up header texts
    pdf.text(header1, margin, 10);
    pdf.text(header2, margin, 21);
    pdf.text(header3, margin, 28);
    pdf.text(header4, margin, 39);
  }

  private setBodyPdfData(pdf: jsPDF, order: Order): void {
    if (!order) return;
    const groups = this.setPdfBodyGroups(order);
    if (!groups) return;
    const pageWidth = pdf.internal.pageSize.width; // Get full PDF width
    const margin = 10;
    const tableWidth = pageWidth - margin * 2;
    const rowHeight = 7;
    const startX = margin;
    const startY = 42;
    let currentY = startY + rowHeight;
    // Table Rows
    groups.map((group) => {
      // set up group's title
      pdf.setFillColor(220, 220, 220);
      pdf.setTextColor('#000000');
      pdf.rect(startX, currentY, tableWidth, rowHeight, 'FD');
      pdf.text(group.title, startX + 2, currentY + 5);
      currentY += rowHeight;

      if (!group.data) return;

      group.data.map((data, index, array) => {
        // set up group's rows
        const isLast = index === array.length - 1;
        if (isLast) {
          pdf.line(startX, currentY + rowHeight, startX + tableWidth, currentY + rowHeight); // Bottom border
          pdf.line(startX, currentY, startX, currentY + rowHeight); // Left border
          pdf.line(startX + tableWidth, currentY, startX + tableWidth, currentY + rowHeight); // Right border
        } else {
          pdf.line(startX, currentY, startX, currentY + rowHeight); // Left border
          pdf.line(startX + tableWidth, currentY, startX + tableWidth, currentY + rowHeight); // Right border
        }

        pdf.setTextColor('#2A292E');
        pdf.text(data.title, startX + 4, currentY + 5);

        if (data?.text) {
          pdf.setFont('pfd.normal', 'normal');
          pdf.text(data.text, startX + 74, currentY + 5);
          pdf.setFont('pfd.bold', 'bold');
        }

        currentY += rowHeight;
        if (isLast) currentY += 4;
      });
    });
  }

  private setPdfBodyGroups(order: Order): PDFGroup[] {
    if (!order) return;
    const group1: PDFGroup = {
      title: translate('document_type_uppercase'),
      data: [{ title: translate('delivery_details_uppercase') }]
    };

    const group2: PDFGroup = {
      title: translate('the_order_uppercase'),
      data: [
        { title: translate('code_uppercase') + ':', text: order.friendlyId },
        { title: translate('creation_date_uppercase') + ':', text: dayjs(order.createdAt).format('DD/MM/YYYY HH:mm') }
      ]
    };

    const user = this.globalStateService.getUser();

    const group3: PDFGroup = {
      title: translate('recipient_uppercase'),
      data: [
        { title: translate('full_name_uppercase') + ':', text: getUserFullName(user) },
        { title: translate('address_uppercase') + ':', text: getAddressDescription(order.address) },
        { title: translate('phone_uppercase') + ':', text: order.orderContactPhone }
      ]
    };

    const group4: PDFGroup = {
      title: translate('store_uppercase'),
      data: [
        { title: translate('brand_name_uppercase') + ':', text: order.shop.name },
        { title: translate('afm_') + ':', text: order.shop.taxNumber ?? '-' },
        { title: translate('gemh_number_uppercase') + ':', text: order.shop?.legalInfo?.commercialRegister ?? '-' },
        { title: translate('address_uppercase') + ':', text: getAddressDescription(order.shop.address) },
        { title: translate('phone_uppercase') + ':', text: formatPhoneToITU(order.shop.phone) }
      ]
    };

    return [group1, group2, group3, group4];
  }
}
