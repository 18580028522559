<div class="order-delivery-details-wrapper">
  <header class="order-delivery-details-header">
    <h3 class="order-delivery-details-header-title ods-typography-subtitle-s">
      {{ 'delivery_details' | translate }}
    </h3>
  </header>
  <main class="order-delivery-details-main">
    <div class="order-delivery-details-main-container">
      <div class="order-delivery-details-group">
        <span class="ods-typography-body-s-bold">{{ 'the_order' | translate }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'code_' | translate }} {{ friendlyId }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'creation_date' | translate }}: {{ orderCreatedAtText }}</span
        >
      </div>

      <div class="order-delivery-details-group">
        <span class="ods-typography-body-s-bold">{{ 'store_' | translate }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'brand_name' | translate }}: {{ shopName }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'afm_' | translate }}: {{ shopTaxNumber }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'gemh_number' | translate }}: {{ shopCommercialRegister }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'address_' | translate }}: {{ shopAddress }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'phone_' | translate }}: {{ shopPhone }}</span
        >
      </div>

      <div class="order-delivery-details-group">
        <span class="ods-typography-body-s-bold">{{ 'recipient_' | translate }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'full_name' | translate }}: {{ recipientName }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'address_' | translate }}: {{ recipientAddress }}</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'phone_' | translate }}: {{ recipientContactPhone }}</span
        >
      </div>

      <div *ngIf="isDaas" class="order-delivery-details-group">
        <span class="ods-typography-body-s-bold">{{ 'administrator_' | translate }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text">{{
          'organism_title' | translate
        }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'afm_' | translate }}: 094019245</span
        >
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text">{{
          'gaty_number' | translate
        }}</span>
        <span class="ods-typography-microcopy-regular order-delivery-details-group-text"
          >{{ 'phone_' | translate }}: {{ administratorContactPhone }}</span
        >
      </div>
    </div>
  </main>
  <footer *ngIf="showDocumentsButton" class="order-delivery-details-footer">
    <button box-button borders="none" theme="secondary" (click)="openDocumentsDialog()">
      <span class="order-delivery-details-button-content ods-typography-body-m-bold">
        <span>{{ 'documents_' | translate }}</span>
        <svg height="24" width="24" viewBox="0 0 24 24">
          <g fill="#F87A1D" fill-rule="nonzero">
            <path
              d="M18.172 6.103H5.828a.39.39 0 0 1-.39-.387v-1.01C5.438 3.765 6.207 3 7.155 3h9.688c.949 0 1.718.764 1.718 1.707v1.009a.39.39 0 0 1-.39.387ZM19.89 7.345H4.11C2.945 7.345 2 8.285 2 9.44v7.293c0 1.155.946 2.095 2.11 2.095h1.327v-3.104c0-1.54 1.262-2.793 2.813-2.793h7.5c1.55 0 2.813 1.253 2.813 2.793v3.104h1.328c1.163 0 2.109-.94 2.109-2.095V9.44a2.105 2.105 0 0 0-2.11-2.095ZM5.75 12a.934.934 0 0 1-.937-.931c0-.514.42-.931.937-.931.519 0 .938.417.938.931s-.42.931-.937.931Z"
            />
            <path
              d="M15.75 14.793h-7.5a.934.934 0 0 0-.938.931V20.07c0 .514.42.931.938.931h7.5a.934.934 0 0 0 .938-.931V15.724a.934.934 0 0 0-.938-.93Z"
            />
          </g>
        </svg>
      </span>
    </button>
  </footer>
</div>
