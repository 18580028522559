import { Injectable } from '@angular/core';
import { ShopService, LoaderService, CoreService } from '@box-core/services';
import { Category, Product, ProductsByCategory, ShopMenuItems, CategoryItems } from '@box-types';
import { ShopPageService } from '@box-delivery/services/shop-page.service';
import {
  getDefaultExtraCategory,
  categorizeProducts,
  categorizeOfferProducts,
  getSuggestedProducts,
  populateOffersCategoryWithChildren
} from '@box/utils';
import { combineLatest, Observable } from 'rxjs';
import { map, finalize, tap } from 'rxjs/operators';
import { ShopMenuNavService } from '@box-delivery/services';

@Injectable()
export class ShopMenuItemsService {
  constructor(
    private shopService: ShopService,
    private shopPageService: ShopPageService,
    private menuNavService: ShopMenuNavService,
    private loaderService: LoaderService
  ) {}

  public getFoodMenuItems(): Observable<ShopMenuItems> {
    return combineLatest([
      this.shopService.menuOffers,
      this.shopService.menuProducts,
      this.shopService.categories,
      this.shopService.usersFrequentPlates
    ]).pipe(
      map(([offers, products, categories, usersFrequentPlates]) => {
        const productsByCategory = this.categorizeProducts(categories, products, usersFrequentPlates);
        return { offers, products, categories, productsByCategory };
      })
    );
  }

  public getCategoryItems(categoryId: string): Observable<CategoryItems> {
    const shop = this.shopService.getShop();

    this.loaderService.setState(true);
    return this.shopService.fetchCategoryItems(shop.collectionType, shop.supermarketGroup, categoryId).pipe(
      finalize(() => this.loaderService.setState(false)),
      tap((categoryItems) => {
        const shopItems = this.shopService.getShopItemsFromMemory();
        shopItems.offers = categoryItems.offers;
        shopItems.products = categoryItems.products;
        const decoratedProducts = this.shopPageService.generateShopMenuProducts(shop, shopItems);
        const decoratedOffers = this.shopPageService.generateShopMenuOffers(shop, shopItems);
        const populatedCategories =
          categoryId === 'client_offers_category'
            ? populateOffersCategoryWithChildren(shopItems.categories, decoratedProducts, decoratedOffers, shop)
            : shopItems.categories;
        this.shopService.setItems({
          ...shopItems,
          offers: decoratedOffers,
          products: decoratedProducts,
          categories: populatedCategories
        });
        this.shopService.syncCartToMenuState();
      })
    );
  }

  public getSmMenuItems(): Observable<ShopMenuItems> {
    return combineLatest([
      this.shopService.menuOffers,
      this.shopService.menuProducts, // either normal or preview
      this.shopService.categories
    ]).pipe(
      map(([offers, products, categories]) => {
        const productsByCategory = this.categorizeSuperMarketProducts(products, categories);
        return { offers, products, categories, productsByCategory };
      })
    );
  }

  // this has to be called every time the quantity changes and after initializeCart
  public categorizeSuperMarketProducts(products: Product[], populatedCategories: Category[]): ProductsByCategory {
    const selectedParentCategoryId = this.menuNavService.selectedParentCategory?.getValue()?._id;
    if (!selectedParentCategoryId || !populatedCategories?.length) return;
    const selectedCategory = populatedCategories.find((cat) => cat._id === selectedParentCategoryId);
    if (!selectedCategory) return;
    const categoriesToGroupBy = selectedCategory?.children?.length ? selectedCategory.children : [selectedCategory];
    if (selectedParentCategoryId !== 'client_offers_category') {
      return categorizeProducts(categoriesToGroupBy, products);
    } else {
      const shop = this.shopService.getShop();
      const offerProducts = products.filter((product) => product.isOffer);
      return categorizeOfferProducts(categoriesToGroupBy, offerProducts, shop);
    }
  }

  private categorizeProducts(
    categories: Category[],
    products: Product[],
    usersFrequentPlates?: string[]
  ): ProductsByCategory {
    if (!categories?.length) return;
    const productsByCategory = categorizeProducts(categories, products);
    const productSuggestions = getSuggestedProducts(products, usersFrequentPlates);
    if (!productSuggestions?.length) return productsByCategory;
    const extraCategories = this.shopPageService.getDefaultExtraCategories();
    const suggestionsCategory = getDefaultExtraCategory(extraCategories, 'suggestions_category');
    productsByCategory[suggestionsCategory._id] = { available: productSuggestions, unavailable: [] };
    return productsByCategory;
  }
}
