import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { APIError, Order } from '@box-types';

import { OrderStatusDocumentsDialogService } from './order-status-documents-dialog.service';
import { DialogService, LoaderService, OrdersService } from '@box-core/services';
import { finalize } from 'rxjs';

declare interface TimeslotRadioOption {
  name: string;
  label: string;
  disabled?: boolean;
}

const DELIVERY_DATA_OPTION: TimeslotRadioOption = { name: 'delivery_data', label: 'delivery_details' };
const DELIVERY_RECEIPT_OPTION: TimeslotRadioOption = { name: 'delivery_receipt', label: 'delivery_receipt' };

@Component({
  selector: 'order-status-documents-dialog',
  templateUrl: './order-status-documents-dialog.component.html',
  styleUrls: ['./order-status-documents-dialog.component.scss'],
  providers: [OrderStatusDocumentsDialogService]
})
export class OrderStatusDocumentsDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public radioButtonOptions: TimeslotRadioOption[];
  public selectedRadioButton: string;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<OrderStatusDocumentsDialogComponent>,
    private orderStatusDocumentsDialogService: OrderStatusDocumentsDialogService,
    private loaderService: LoaderService,
    private ordersService: OrdersService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: Order
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.radioButtonOptions = this.getRadioButtonOptions();
    this.selectedRadioButton = this.radioButtonOptions[0].name;
  }

  private getRadioButtonOptions(): TimeslotRadioOption[] {
    return [DELIVERY_DATA_OPTION, DELIVERY_RECEIPT_OPTION];
  }

  public onChangeRadioButton(event: MatRadioChange) {
    this.selectedRadioButton = event.value as string;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    const type = this.selectedRadioButton;
    if (type === 'delivery_data') {
      this.orderStatusDocumentsDialogService.generateOrderDeliveryDetailsPDF(this.data);
      return this.closeDialog();
    }
    if (type === 'delivery_receipt') {
      this.handleDaasOrderReceipt(this.data.friendlyId);
      return this.closeDialog();
    }
  }

  public handleDaasOrderReceipt(friendlyId: string): void {
    this.loaderService.setState(true);
    this.ordersService
      .fetchDaasOrderReceipt(friendlyId)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (url) => window.open(url, '_blank'),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }
}
