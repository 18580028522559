import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigurationService } from '@box-core/services';
import { getLegalDocumentBody, ObservableCacheDecorator } from '@box/utils';
import { TermsTemplateType } from '@box-types';

@Injectable({ providedIn: 'root' })
export class LegalDocumentsService {
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService
  ) {}

  @ObservableCacheDecorator<string, string>({
    functionKeyArgumentIndexes: [0]
  })
  public fetchTermsAndConditions(templateType: TermsTemplateType): Observable<string> {
    const TERMS_URL = this.getLegalDocumentUrl(templateType);
    return this.http.get(TERMS_URL, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  @ObservableCacheDecorator<string>()
  public fetchPrivacyTerms(): Observable<string> {
    const PRIVACY_TERMS = this.getLegalDocumentUrl('privacy');
    return this.http.get(PRIVACY_TERMS, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  @ObservableCacheDecorator<string>()
  public fetchMarketingTerms(): Observable<string> {
    const MARKETING_TERMS = this.getLegalDocumentUrl('marketingTerms');
    return this.http.get(MARKETING_TERMS, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  private getLegalDocumentUrl(key: string): string {
    if (!key) return;
    return this.configService.getConfiguration()?.legalDocuments?.[key] as string;
  }
}
