import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoxShellComponent } from '@box-core/components';
import * as Guards from '@box-core/guards';
import * as Pages from '@box-core/pages';
import * as Resolvers from '@box-core/resolvers';

const routes: Routes = [
  {
    path: '',
    resolve: {
      coreItems: Resolvers.CoreResolver
    },
    children: [
      { path: '', loadChildren: () => import('@box-pages/pages.module').then((m) => m.PagesModule) },
      {
        path: '',
        resolve: {
          campaigns: Resolvers.LoyaltyResolver,
          promoCampaigns: Resolvers.PromoCampaignsResolver,
          addresses: Resolvers.AddressesResolver
        },
        component: BoxShellComponent,
        children: [
          { path: '', loadChildren: () => import('@box-legal/legal.module').then((m) => m.LegalModule) },
          {
            path: 'home',
            component: Pages.HomePage,
            canActivate: [Guards.PromoRedirectGuard],
            resolve: { address: Resolvers.AddressResolver },
            runGuardsAndResolvers: 'always',
            data: { name: 'Home Page' }
          },
          { path: 'promos', redirectTo: 'home' },
          { path: 'shops/:oldVanityUrl', children: [], canActivate: [Guards.ShopRedirectGuard] },
          { path: 'shops/:oldVanityUrl/details', children: [], canActivate: [Guards.ShopRedirectGuard] },
          { path: 'shops/:oldVanityUrl/reviews', children: [], canActivate: [Guards.ShopRedirectGuard] },
          {
            path: 'delivery',
            loadChildren: () => import('@box-delivery/delivery.module').then((m) => m.DeliveryModule),
            data: { name: 'Delivery', breadcrumbName: 'delivery_' }
          },
          {
            path: 'delivery-chains',
            loadChildren: () => import('@box-delivery/delivery.module').then((m) => m.DeliveryModule),
            data: { name: 'Delivery', breadcrumbName: 'delivery_' }
          },
          {
            path: 'delivery-promos',
            loadChildren: () => import('@box-delivery/delivery.module').then((m) => m.DeliveryModule),
            data: { name: 'Promo Page', breadcrumbName: 'skipBreadcrumb' }
          },
          {
            path: 'contests',
            loadChildren: () => import('@box-contest/contest.module').then((m) => m.ContestModule)
          },
          {
            path: 'discover',
            loadChildren: () => import('@box-discover/discover.module').then((m) => m.DiscoverModule),
            data: { name: 'Discovery', breadcrumbName: 'discover_' },
            resolve: { address: Resolvers.AddressResolver }
          },
          {
            path: 'rewards',
            data: { name: 'Rewards', breadcrumbName: 'points_' },
            loadChildren: () => import('@box-rewards/rewards.module').then((m) => m.RewardsModule)
          },
          {
            path: 'account',
            loadChildren: () => import('@box-account/account.module').then((m) => m.AccountModule),
            data: { name: 'Account', breadcrumbName: 'account_' }
          },
          {
            path: 'checkout',
            loadChildren: () => import('@box-checkout/checkout.module').then((m) => m.CheckoutModule),
            canActivate: [Guards.AuthGuard]
          },
          {
            path: 'payment',
            children: [],
            canActivate: [Guards.AuthGuard],
            resolve: { payment: Resolvers.PaymentResolver },
            data: { name: 'Payment Page' }
          },
          {
            path: 'chains/closest-shop/:chainKey',
            children: [],
            resolve: { shop: Resolvers.ClosestChainShopResolver }
          }
        ]
      },
      { path: 'download', component: Pages.DownloadPage, data: { name: 'Download Page' } }
    ]
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        children: [],
        resolve: { payment: Resolvers.LoginResolver },
        data: { name: 'Login Page', breadcrumbName: 'login_' }
      },
      {
        path: 'redirect',
        children: [],
        resolve: { payment: Resolvers.RedirectResolver },
        data: { name: 'Login Redirect Page' }
      }
    ]
  },
  {
    path: 'logout',
    children: [],
    resolve: { payment: Resolvers.LogoutResolver },
    data: { name: 'Logout Page' }
  },
  { path: '404', component: Pages.FourOhFourPage, data: { name: '404 Page' } },
  { path: '**', component: Pages.FourOhFourPage, data: { name: '404 Page' } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class BoxRoutingModule {}
