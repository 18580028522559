import { Injectable, OnDestroy } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, throwError, of, Subscription, Subscriber, forkJoin, firstValueFrom } from 'rxjs';
import { tap, catchError, map, switchMap } from 'rxjs/operators';

import {
  CoreService,
  SentryService,
  GlobalPopupsService,
  CouponsService,
  CouponTimerService,
  LanguageService,
  ConfigurationService,
  UserService,
  AuthenticationService,
  DialogService,
  ExplorePromoCampaignService
} from '@box-core/services';
import { CoreItems, APIError, Coupon, ConfigurationToBeHydrated, User, UserEvent } from '@box-types';
import { GlobalStateService } from '@box-core/services/global-state.service';
import { ThemingService } from '@box-core/services/theming.service';
import { configurationV2 } from '@box-assets/configurationV2';
import { DelayedRetryService } from '@box-core/services/delayed-retry.service';
import { appendMsisdnToUserAssets, isLowOrderProbabilityUser, filterEnabledItems } from '@box/utils';
import { PhoneVerificationDialogResponse } from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { MatDialogConfig } from '@angular/material/dialog';
import { PhoneVerificationDialogComponent } from '@box-shared/components';
import { UserEventsService } from '@box-core/services/user-events.service';

@Injectable({ providedIn: 'root' })
export class CoreResolver implements OnDestroy, Resolve<void> {
  private decoratedCouponsSubscription: Subscription;

  constructor(
    private coreService: CoreService,
    private globalPopupsService: GlobalPopupsService,
    private couponsService: CouponsService,
    private sentryService: SentryService,
    private couponTimerService: CouponTimerService,
    private globalStateService: GlobalStateService,
    private languagePreferenceService: LanguageService,
    private themingService: ThemingService,
    private configService: ConfigurationService,
    private delayedRetryService: DelayedRetryService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialogService: DialogService,
    private explorePromoCampaignService: ExplorePromoCampaignService,
    private userEventsService: UserEventsService
  ) {}

  async resolve(activatedRouteSnapshot: ActivatedRouteSnapshot): Promise<void> {
    await firstValueFrom(forkJoin([this.resolveUser(), this.resolveConfiguration(), this.resolveTheme()]));
    await firstValueFrom(this.resolveLanguage(activatedRouteSnapshot));
    await firstValueFrom(
      forkJoin([this.resolveGlobalPopups(), this.resolveCoupons(), this.resolveCoreItems()]).pipe(
        tap(() => this.setEligibleGroupings())
      )
    );
  }

  ngOnDestroy(): void {
    this.decoratedCouponsSubscription?.unsubscribe();
  }

  private resolveUser(): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      const BOX_TOKEN = this.authenticationService.BOX_TOKEN;

      if (!BOX_TOKEN) {
        this.userService.initUser();
        subscriber.next(this.globalStateService.getUser());
        subscriber.complete();
        return;
      }

      forkJoin({
        user: this.userService.fetchUser(),
        events: this.handleUserEvents$()
      })
        .pipe(
          switchMap(({ user }) => this.verifyUserPhone$(user)),
          switchMap((user) => this.handleUserAssets$(user)),
          switchMap((user) => this.handleLowOrderProbability$(user))
        )
        .subscribe({
          next: (user) => {
            this.userService.initUser(user);
            subscriber.next(this.globalStateService.getUser());
            subscriber.complete();
          },
          error: () => {
            this.authenticationService.clearCosmoteTokens();
            this.authenticationService.clearBoxToken();
            window.sessionStorage.removeItem('BOX:mb');
            this.userService.initUser();
            subscriber.next(this.globalStateService.getUser());
            subscriber.complete();
            void this.router.navigate(['/']);
          }
        });
    });
  }

  private resolveLanguage(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    this.languagePreferenceService.initializeLanguagePreference(activatedRouteSnapshot);
    this.languagePreferenceService.initializeLocalClientTranslations();
    return this.languagePreferenceService.initializeConfiguration$();
  }

  private resolveCoreItems(): Observable<CoreItems> {
    return this.coreService.fetchItems().pipe(
      map((items) => this.coreService.filterEnabledItems(items)),
      tap((items) => this.coreService.setItems(items)),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Core',
          domainDetails: 'Core Resolver',
          severity: 'warning'
        });
        return throwError(() => error);
      })
    );
  }

  private resolveGlobalPopups(): Observable<boolean> {
    this.globalPopupsService.initializeGlobalPopups();
    return of(true);
  }

  private resolveCoupons(): Observable<Coupon[]> {
    return this.couponsService.fetchAvailableCoupons$().pipe(
      tap((coupons) => {
        this.globalStateService.setAvailableCoupons(coupons);
        this.decoratedCouponsSubscription = this.couponsService.getDecoratedCouponsSubscription();
        this.couponTimerService.initialize(coupons);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Rewards Balance Badge Coupons',
          severity: 'warning'
        });
        return of([]);
      })
    );
  }

  private setEligibleGroupings(): void {
    const userSegments = this.globalStateService.getUser()?.segments ?? [];
    const availableCoupons = this.globalStateService.getAvailableCoupons();
    const groupings = this.coreService.groupings.getValue();
    const eligibleGroupings = filterEnabledItems(groupings, userSegments, availableCoupons);
    this.globalStateService.setEligibleGroupings(eligibleGroupings);
  }

  private resolveTheme(): Observable<boolean> {
    this.themingService.initializeTheme();
    return of(true);
  }

  private resolveConfiguration(): Observable<ConfigurationToBeHydrated> {
    return this.configService.fetchConfiguration().pipe(
      this.delayedRetryService.delayedRetry(),
      catchError((error: APIError) => {
        this.sentryService.captureException(error);
        return of(configurationV2 as ConfigurationToBeHydrated);
      }),
      tap((config) => this.configService.setConfiguration(config))
    );
  }

  private handleUserEvents$(): Observable<UserEvent[]> {
    return this.userEventsService.fetchUserEventHistory().pipe(
      tap((events) => {
        this.userEventsService.setUserEventHistory(events);
      })
    );
  }

  private handleUserAssets$(user: User): Observable<User> {
    return this.userService.fetchCosmoteAssets().pipe(
      map((assets) => appendMsisdnToUserAssets(user, assets)),
      catchError(() => of([])),
      switchMap((assets) =>
        this.userService.fetchSegmentInfo(assets).pipe(
          map((segmentInfoResponse) => ({ ...user, ...segmentInfoResponse })),
          catchError(() => of(user))
        )
      )
    );
  }

  private handleLowOrderProbability$(user: User): Observable<User> {
    if (!isLowOrderProbabilityUser(user)) return of(user);
    return this.explorePromoCampaignService.fetchExploreCouponData().pipe(
      map((exploreData) => {
        const { coupon, userSegments } = exploreData;
        if (coupon) this.explorePromoCampaignService.initialize(coupon);
        return { ...user, segments: userSegments };
      }),
      catchError(() => of(user))
    );
  }

  private verifyUserPhone$(user: User): Observable<User> {
    return new Observable((subscriber: Subscriber<User>) => {
      if (!user.requiresMSISDNVerification) {
        subscriber.next(user);
        subscriber.complete();
      } else {
        const dialogConfig: MatDialogConfig = {
          disableClose: true,
          data: { verificationReason: 'mobile_phone_initialize' }
        };
        this.dialogService
          .openDialog(PhoneVerificationDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((response: PhoneVerificationDialogResponse) => {
            if (!response?.success || !response?.verifyResponse) {
              subscriber.error();
            } else {
              subscriber.next({ ...user, ...response.verifyResponse });
            }
            subscriber.complete();
          });
      }
    });
  }
}
