import { Injectable } from '@angular/core';
import { Offer } from '@box-types';
import { generateCDNImageSrc, generateImageSrc } from '@box/utils';
import orderBy from 'lodash-es/orderBy';

@Injectable()
export class OfferWizardService {
  public getOfferHeroImageSrc(offer: Offer): string {
    if (offer?.image) return generateCDNImageSrc(offer.image, 'MYO');
    return generateImageSrc(offer.webImage);
  }

  public getOfferImages(offer: Offer): string[] {
    if (offer.images?.length) {
      const sortedImages = orderBy(offer.images, 'index', 'asc');
      return sortedImages.map((image) => generateCDNImageSrc(image, 'MYO'));
    }
    if (offer.webImages?.length) {
      const sortedImages = orderBy(offer.webImages, 'galleryIndex', 'asc');
      return sortedImages.map((image) => generateImageSrc(image));
    }
    return [];
  }
}
