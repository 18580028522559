<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="'select_document'" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section class="order-status-documents-dialog-section">
      <mat-radio-group class="order-status-documents-dialog-options-radio" (change)="onChangeRadioButton($event)">
        <mat-radio-button
          *ngFor="let option of radioButtonOptions"
          color="primary"
          class="box-radio-button order-status-documents-dialog-radio-button"
          [value]="option.name"
          [disabled]="option.disabled"
          [checked]="option.name === selectedRadioButton"
        >
          <span class="ods-typography-body-s-bold">{{ option.label | translate }}</span>
        </mat-radio-button>
      </mat-radio-group>
    </section>
  </main>

  <footer #dialogFooter class="box-dialog-footer order-status-documents-dialog-footer">
    <button class="order-status-documents-dialog-button" box-button theme="primary" (click)="onSubmit()">
      {{ 'print_' | translate }}
    </button>
  </footer>
</div>
